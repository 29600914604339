import React, { useState } from "react";
import login from "../../../assets/authentication/bg.jpg";
import logo from "../../../assets/authentication/logobharat.png";
import { useFormik } from "formik";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Button, Collapse, TextField } from "@mui/material";
import { toast } from "react-toastify";
import { baseURL } from "../../../config/axios";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [key, setKey] = useState([]);
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const [confirmed, setConfirmed] = useState(false);

  const [checked, setChecked] = React.useState(true);

  const SendOtp = (event) => {
    event.preventDefault();
    axios
      .post(`${baseURL}reseller_app/send-reseller-forgot-password-otp-api/`, {
        email: email,
      })
      .then((response) => {
        setKey(response.data.otp_key);
        toast.success(response.data.message);
        response.data.response_code === 200 && setChecked(false);
        // navigate("/");
      })
      .catch((error) => {
        console.log("", error.response);
        console.log(error);
      });
  };
  const ResatePassword = (event) => {
    event.preventDefault();
    const reqbody = new FormData();
    reqbody.append("email", email);
    reqbody.append("new_password", password);
    reqbody.append(`${key}`, otp);
    axios
      .post(`${baseURL}reseller_app/forgot-reseller-password-api/`, reqbody)
      .then((response) => {
        toast.success(response.data.message);
        navigate("/");
      })
      .catch((error) => {
        console.log("", error.response);
        console.log(error);
      });
  };
  // const initialValues = {
  //   email: "",
  //   otp: "",
  //   new_password: "",
  //   confirm_password: "",
  // };
  // const formik = useFormik({
  //   initialValues: initialValues,
  //   onSubmit: (values, action) => {
  //     const reqBody = new FormData();
  //     reqBody.append("email", values.email);
  //     confirmed === true && reqBody.append("otp", values.otp);
  //     confirmed === true && reqBody.append("new_password", values.new_password);
  //     // confirmed === true &&
  //     //   reqBody.append("confirm_password", values.confirm_password);

  //     {
  //       confirmed === false
  //         ? axios
  //             // .post(`${baseURL}password-forget-email/`, {
  //             .post(
  //               `${baseURL}reseller_app/send-reseller-reset-password-otp-api/`,
  //               reqBody,
  //               { withCredentials: true }
  //             )
  //             .then((response) => {
  //               if (response?.data?.response_code === 200) {
  //                 setConfirmed(true);
  //                 toast.success(response.data.message);
  //               } else {
  //                 // navigate("/");
  //                 toast.error(response.data.message);
  //               }
  //             })
  //             .catch((error) => {
  //               console.log("", error.response);
  //               console.log(error);
  //             })
  //         : axios
  //             // .post(`${baseURL}password-forget-email/`, {
  //             .post(
  //               `${baseURL}reseller_app/forgot-reseller-password-api/`,
  //               reqBody,
  //               { withCredentials: true }
  //             )
  //             .then((response) => {
  //               if (response?.data?.response_code === 200) {
  //                 toast.success(response.data.message);
  //                 navigate("/");
  //               } else {
  //                 toast.error(response.data.message);
  //               }
  //             })
  //             .catch((error) => {
  //               console.log("", error.response);
  //               console.log(error);
  //             });
  //     }
  //   },
  // });

  return (
    <>
      <div className="flex">
        <img
          src={login}
          alt=""
          className="h-screen fixed object-cover w-screen"
        />
        <div className="flex flex-col h-screen w-screen items-center lg:p-16 p-8  text-black lg:w-[30%] lg:border-r-2 border-opacity-10 bg-white bg-opacity-10 lg:backdrop-blur-[6px] backdrop-blur-lg">
          <img src={logo} alt="" className="h-28 mt-20" />
          <div>
            <p className="text-4xl text-center font-semibold mt-10">
              Reset Password
            </p>
            <p className="my-2 text-center text-xs">
              Enter your email address and we’ll send you an email with
              instructions to reset your password
            </p>
          </div>
          {checked ? (
            <form onSubmit={SendOtp} className="flex flex-col w-full my-2">
              <label className="py-1">Email*</label>
              <TextField
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                className="!rounded-md text-black px-2 p-2 focus:!outline-none !border-2 bg-white bg-opacity-20 backdrop-blur-xl-sm"
              />

              <div className="flex justify-center items-center mt-5">
                <Button
                  disableElevation
                  variant="contained"
                  type={"submit"}
                  className="!px-10 !bg-blue-600 !rounded-md !p-1.5 !capitalize !mx-0"
                >
                  Sent
                </Button>
              </div>
            </form>
          ) : (
            <form
              onSubmit={ResatePassword}
              className="flex flex-col w-full my-2"
            >
              <label className="py-1">New Password</label>
              <TextField
                id="password"
                name="password"
                size="password"
                placeholder="Password"
                type="passwrod"
                value={password}
                
                onChange={(e) => setPassword(e.target.value)}
                className="!rounded-md text-black px-2 p-2 focus:!outline-none !border-2 bg-white bg-opacity-20 backdrop-blur-xl-sm"
              />
              <label className="py-1">Otp</label>
              <TextField
                id="otp"
                name="otp"
                size="otp"
                placeholder="Otp"
                type="text"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className="!rounded-md text-black px-2 p-2 focus:!outline-none !border-2 bg-white bg-opacity-20 backdrop-blur-xl-sm"
              />

              <div className="flex justify-center items-center mt-5">
                <Button
                  disableElevation
                  variant="contained"
                  type={"submit"}
                  className="!px-10 !bg-blue-600 !rounded-md !p-1.5 !capitalize !mx-0"
                >
                  Reseat Password
                </Button>
              </div>
            </form>
          )}
          {/* <form
            onSubmit={formik.handleSubmit}
            className="flex flex-col w-full my-2"
          >
            <label className="py-1">Email*</label>

            <TextField
              id="email"
              name="email"
              size="small"
              placeholder="Email"
              value={formik.values["email"]}
              onChange={formik.handleChange}
              onBlur={formik.onBlur}
              // onError={formik.errors && formik.touched ? true : false}
              type="email"
              className="!rounded-md text-black px-2 p-2 focus:!outline-none !border-2 bg-white bg-opacity-20 backdrop-blur-xl-sm"
            />

            <Button
              disableElevation
              disabled={confirmed}
              variant="contained"
              type={"submit"}
              className="!px-10 !bg-blue-600 !rounded-md !p-1.5 !capitalize !mx-0"
            >
              "Sent Otp"
            </Button>
            <label className="py-1">OTP*</label>

            <TextField
              id="otp"
              name="otp"
              size="small"
              placeholder="otp"
              value={formik.values["otp"]}
              onChange={formik.handleChange}
              onBlur={formik.onBlur}
              // onError={formik.errors && formik.touched ? true : false}
              type="text"
              className="!rounded-md text-black px-2 p-2 focus:!outline-none !border-2 bg-white bg-opacity-20 backdrop-blur-xl-sm"
            />
            <label className="py-1">New Password*</label>

            <TextField
              id="new_password"
              name="new_password"
              size="small"
              placeholder="New Password"
              value={formik.values["new_password"]}
              onChange={formik.handleChange}
              onBlur={formik.onBlur}
              // onError={formik.errors && formik.touched ? true : false}
              type="password"
              autoComplete="off"
              className="!rounded-md text-black px-2 p-2 focus:!outline-none !border-2 bg-white bg-opacity-20 backdrop-blur-xl-sm"
            />

           
            <div className="flex justify-center items-center mt-5">
              <Button
                disableElevation
                variant="contained"
                disabled={!confirmed}
                type={"submit"}
                className="!px-10 !bg-blue-600 !rounded-md !p-1.5 !capitalize !mx-0"
              >
                "Confirm Otp"
              </Button>
            </div>
          </form> */}
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
