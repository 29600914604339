import { FilterAlt } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { resellerStoreListFn } from "../../../Services/Resellers/ResellerStoreList";
import { Active, Inactive } from "../../../shared/ActivaInactive";
import { CustomButton } from "../../../shared/CustomButton";
import { CustomIconButton } from "../../../shared/CustomIconButton";
import Loader from "../../../shared/Loader";
import Search from "../../../shared/Search";
import StoreCreation from "../StoreCreation";
import DownloadTwoToneIcon from "@mui/icons-material/DownloadTwoTone";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import LockIcon from "@mui/icons-material/Lock";
import axios from "axios";
import { toast } from "react-toastify";
import AddStore from "../AddStore";
import { baseURL } from "../../../config/axios";
const StoreList = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [loding, setLoding] = useState(true);
  const [linkData, setLinkData] = useState();
  const [id1, setId] = useState("");
  const tableRef = useRef(null);
  const handleClose = () => setOpen(false);

  const handleOpen = (id) => {
    setOpen(true);
    setId(id);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const { data: storeList } = useQuery(
    ["resellerStoreList"],
    resellerStoreListFn,

    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  useEffect(() => {
    setData(storeList?.data.data);
  }, [storeList]);

  const navigate = useNavigate();
  useEffect(
    () => {
      localStorage.getItem("role") === "reseller"
        ? navigate("#")
        : navigate("/");
    },
    // eslint-disable-next-line
    []
  );

  const LinkHandel = (e, id) => {
    e.preventDefault();
    axios
      .post(
        `${baseURL}reseller_app/reseller-send-link-to-reseller-customer-api-of-bhaaraterp/`,
        {
          reseller_customer_id: id,
        },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        toast.success(response.data.message);
        // response.data.response_code === 200 && handleChange1();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <div className="">
        <div className="flex justify-between pr-2">
          <div className="p-2">
            <p className="text-lg lg:text-xl font-semibold   lg:font-bold">
              <span className="flex lg:hidden">Reseller Customers</span>
              <span className="hidden lg:flex">Reseller Customer List</span>
            </p>
          </div>
          <StoreCreation />
        </div>
        <div className="flex flex-col !rounded border-2 border-white border-opacity-40 backdrop-blur-xl my-2 lg:m-2">
          <div className="flex items-center justify-between my-2">
            <div className="flex  gap-3">
              <CustomIconButton>
                <FilterAlt />
              </CustomIconButton>
              <Search />
            </div>
            <DownloadTableExcel
              filename="BhaaratERP"
              sheet="users"
              currentTableRef={tableRef.current}
            >
              <CustomButton className="!mx-3 !font-semibold">
                <DownloadTwoToneIcon className="mr-1" /> Export
              </CustomButton>
            </DownloadTableExcel>
          </div>

          <TableContainer
            component="div"
            className="bg-white bg-opacity-30 backdrop-blur-xl"
          >
            <Table ref={tableRef}>
              <TableHead>
                <TableRow className="!border-b-2 border-black">
                  <TableCell className="!text-left !p-2">
                    <Checkbox className="!m-0" />
                  </TableCell>
                  <TableCell className="!text-center !font-bold">
                    Name
                  </TableCell>
                  <TableCell className="!text-center !font-bold">
                    Email
                  </TableCell>
                  <TableCell className="!text-center !font-bold">
                    Plan Type
                  </TableCell>
                  <TableCell className="!text-center !font-bold">
                    Plan
                  </TableCell>
                  <TableCell className="!text-center !font-bold">
                    Period
                  </TableCell>
                  <TableCell className="!text-right !font-bold">
                    Link Status
                  </TableCell>
                  <TableCell className="!text-center !font-bold">
                    Payment Status
                  </TableCell>

                  <TableCell className="!text-right !font-bold">
                    Store Status
                  </TableCell>
                </TableRow>
                {/* {data?.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={8} className="!p-0 !m-0">
                      <Loader />
                    </TableCell>
                  </TableRow>
                ) : null} */}
              </TableHead>

              <TableBody>
                {data
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow key={row.id} className="!border-b-2 border-black">
                      <TableCell
                        className="!text-left !p-2"
                        component="th"
                        scope="row"
                      >
                        <Checkbox className="!m-0" />
                      </TableCell>
                      <TableCell className="!text-center">
                        {row.full_name}
                      </TableCell>
                      <TableCell className="!text-center">
                        {row.email}
                      </TableCell>
                      <TableCell className="!text-center">
                        {row?.plan_type}
                      </TableCell>
                      <TableCell className="!text-center">
                        {row?.plan?.plan_name}
                      </TableCell>
                      <TableCell className="!text-center">
                        <span
                          className={classNames(
                            row?.plan_period === "Monthly" &&
                              "px-2 py-1 bg-pink-400 rounded-md",

                            row?.plan_period === "Yearly" &&
                              "px-3 py-1 bg-teal-400 rounded-md"
                          )}
                        >
                          {row?.plan_period}
                        </span>
                      </TableCell>

                      <TableCell className="!text-center">
                        {row?.link_send_status === "Pending" && (
                          <p
                            className="px-2 py-1 bg-blue-400 whitespace-nowrap rounded-md hover:bg-blue-800 hover:font-bold hover:text-blue-600 cursor-pointer"
                            onClick={(e) => LinkHandel(e, row.id)}
                          >
                            Send link
                          </p>
                        )}
                        {row?.link_send_status === "Sended" && (
                          <p className="px-2 py-1 bg-green-400 rounded-md ">
                            Success
                          </p>
                        )}
                      </TableCell>
                      <TableCell className="!text-center">
                        <span
                          className={classNames(
                            row?.payment_status === "Paid" &&
                              "px-5 py-1 bg-yellow-400 rounded-md",

                            row?.payment_status === "Pending" &&
                              "px-2 py-1 bg-red-400 rounded-md"
                          )}
                        >
                          {row?.payment_status}
                        </span>
                      </TableCell>

                      <TableCell className="!text-center">
                        {row?.plan_type === "ERP" ? (
                          <div>
                            {row?.payment_status === "Pending" && (
                              <p className="px-2 py-1 whitespace-nowrap bg-purple-600 !text-white rounded-md">
                                Please Pay
                              </p>
                            )}
                            {row?.payment_status === "Paid" && (
                              <input
                                type="checkbox"
                                efaultChecked={open}
                                onChange={() => {
                                  handleOpen(row.id);
                                }}
                                className="toggle checked:!border-green-500 !border-red-500 toggle-success"
                              />
                            )}
                          </div>
                        ) : (
                          <LockIcon />
                        )}
                      </TableCell>
                      {/* <TableCell className="!text-center">
                        <span className="flex justify-center items-center">
                          {row.status === 1 ? <Active /> : <Inactive />}
                        </span>
                      </TableCell> */}

                      {/* <TableCell className="!text-right !p-2">
                        <input
                          type="checkbox"
                          defaultChecked={row.status === 1 ? true : false}
                          // onChange={(event) => handleChange(event, row.email)}
                          className="toggle checked:!border-green-500 !border-red-500 toggle-success"
                        />
                      </TableCell> */}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <Modal open={open} onClose={handleClose}>
              <Box
                className="absolute top-1/2 left-1/2 bg-white text-black outline-none -translate-x-1/2 -translate-y-1/2 w-11/12 lg:w-1/2 h-1/2 min-h-[93%]
         shadow-md p-6 rounded-lg overflow-y-auto"
              >
                <AddStore
                  CostemerId={id1}
                  setOpen={setOpen}
                  // email={email}
                  // storePlan={storePlan}
                  // planType={planType}
                />
              </Box>
            </Modal>
            {data?.length !== 0 ? (
              <TablePagination
                rowsPerPageOptions={[6, 12, 18, 24]}
                count={data?.length || 0}
                component="div"
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            ) : null}
          </TableContainer>
        </div>
      </div>
    </>
  );
};

export default StoreList;
