import { FilterAlt } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { resellerStoreListFn } from "../../../Services/Resellers/ResellerStoreList";
import { Active, Inactive } from "../../../shared/ActivaInactive";
import { CustomButton } from "../../../shared/CustomButton";
import { CustomIconButton } from "../../../shared/CustomIconButton";
import Loader from "../../../shared/Loader";
import Search from "../../../shared/Search";
import StoreCreation from "../StoreCreation";
import DownloadTwoToneIcon from "@mui/icons-material/DownloadTwoTone";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { useNavigate } from "react-router-dom";
import AddStore from "../AddStore";
import axios from "axios";
import classNames from "classnames";
import { baseURL } from "../../../config/axios";
const StoreCreate = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [data, setData] = useState([]);
  const tableRef = useRef(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // const { data: storeList } = useQuery(
  //   ["resellerStoreList"],
  //   resellerStoreListFn,

  //   {
  //     refetchOnMount: false,
  //     refetchOnWindowFocus: false,
  //     refetchOnReconnect: false,
  //   }
  // );

  // useEffect(() => {
  //   setData(storeList?.data.data);
  // }, [storeList]);

  const getData = () => {
    axios
      .get(
        `${baseURL}reseller_app/reseller-customer-store-list-api-of-bhaaraterp/?page&search_value&reseller_customer_id`,

        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        setData(response.data.data);
        // toast.success(response.data.message);
      })
      .catch((error) => {
        console.log("", error.response);
        console.log(error);
        // alert(error.message);
      });
  };
  useEffect(() => {
    getData();
  }, []);
  const navigate = useNavigate();
  useEffect(
    () => {
      localStorage.getItem("role") === "reseller"
        ? navigate("#")
        : navigate("/");
    },
    // eslint-disable-next-line
    []
  );

  return (
    <>
      <div className="">
        <div className="flex justify-between pr-2">
          <div className="p-2">
            <p className="text-xl font font-bold">Store List</p>
          </div>
          {/* <StoreCreation /> */}
        </div>
        <div className="flex flex-col !rounded border-2 border-white border-opacity-40 backdrop-blur-xl m-2">
          <div className="flex items-center justify-between my-2">
            <div className="flex  gap-3">
              <CustomIconButton>
                <FilterAlt />
              </CustomIconButton>
              <Search />
            </div>
            <DownloadTableExcel
              filename="BhaaratERP"
              sheet="users"
              currentTableRef={tableRef.current}
            >
              <CustomButton className="!mx-3 !font-semibold">
                <DownloadTwoToneIcon className="mr-1" /> Export
              </CustomButton>
            </DownloadTableExcel>
          </div>

          <TableContainer
            component="div"
            className="bg-white bg-opacity-30 backdrop-blur-xl"
          >
            <Table ref={tableRef}>
              <TableHead>
                <TableRow className="!border-b-2 border-black">
                  <TableCell className="!text-left !p-2">
                    <Checkbox className="!m-0" />
                  </TableCell>
                  <TableCell className="!text-center !font-bold">
                    Name
                  </TableCell>
                  <TableCell className="!text-center !font-bold">
                    Email
                  </TableCell>
                  <TableCell className="!text-center !font-bold">
                    Mobile No.
                  </TableCell>
                  <TableCell className="!text-center !font-bold">
                    PLAN_PERIOD
                  </TableCell>
                  <TableCell className="!text-center !font-bold">
                    Plan Name
                  </TableCell>
                  {/* 
                  <TableCell className="!text-center !font-bold">
                    Status
                  </TableCell> */}
                  {/* <TableCell className="!text-right !font-bold">
                    Actions
                  </TableCell> */}
                </TableRow>
                {/* {data?.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={8} className="!p-0 !m-0">
                      <Loader />
                    </TableCell>
                  </TableRow>
                ) : null} */}
              </TableHead>

              <TableBody>
                {data
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, index) => (
                    <TableRow
                      key={row?.id}
                      className="!border-b-2 border-black"
                    >
                      <TableCell
                        className="!text-left !p-2"
                        component="th"
                        scope="row"
                      >
                        <Checkbox className="!m-0" />
                      </TableCell>
                      <TableCell className="!text-center">
                        {row?.full_name}
                      </TableCell>
                      <TableCell className="!text-center">
                        {row?.email}
                      </TableCell>
                      <TableCell className="!text-center">
                        {row?.reseller?.mobile_number
                          ? row?.reseller?.mobile_number
                          : "--"}
                      </TableCell>
                      <TableCell className="!text-center">
                        <span
                          className={classNames(
                            row?.plan_period === "Monthly" &&
                              "px-2 py-1 bg-pink-500 rounded-md",

                            row?.plan_period === "Yearly" &&
                              "px-3 py-1 bg-teal-400 rounded-md"
                          )}
                        >
                          {row?.plan_period}
                        </span>
                      </TableCell>
                      <TableCell className="!text-center">
                        <span
                          className={classNames(
                            row?.plan?.plan_name === "Enterprise" &&
                              "px-1 py-1 bg-teal-400 rounded-md",
                            row?.plan?.plan_name === "Basic" &&
                              "px-5 py-1 bg-sky-300 rounded-md",
                            row?.plan?.plan_name === "Advance" &&
                              "px-3 py-1 bg-indigo-400 rounded-md"
                          )}
                        >
                          {row?.plan?.plan_name}
                        </span>
                      </TableCell>
                      {/* <TableCell className="!text-center">
                        <span className="flex justify-center items-center">
                          {row.status === 1 ? <Active /> : <Inactive />}
                        </span>
                      </TableCell> */}

                      <TableCell className="!text-right !p-2"></TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            ,
            {data?.length !== 0 ? (
              <TablePagination
                rowsPerPageOptions={[6, 12, 18, 24]}
                count={data?.length || 0}
                component="div"
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            ) : null}
          </TableContainer>
        </div>
      </div>
    </>
  );
};

export default StoreCreate;
