import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { useFormik } from "formik";
import React, { useState } from "react";
import { toast } from "react-toastify";
import CustomInput from "../../../shared/CustomInput";
import { baseURL } from "../../../config/axios";

const StoreUserCreate = ({
  setStatus,
  setEmail,
  setPassword,
  setFirstName,
  setLastName,
}) => {
  const [loading, setLoading] = useState();

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
  };

  const formik = useFormik({
    initialValues: initialValues,

    onSubmit: (values, action) => {
      const reqbody = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        password: values.password,
        username: values.email,
      };
      setLoading(true);

      // axios
      //   .post(
      //     `${baseURL}reseller_app/register-customer/`,
      //     { ...reqbody },
      //     {
      //       headers: {
      //         Authorization: localStorage.getItem("token"),
      //       },
      //     }
      //   )
      //   .then((response) => {
      //     setLoading(false);
      //     setStatus(response.data.response_code);
      //     if (response.data.response_code === 200) {
      //       toast.success("User Craeted Successfully");
      //     }
      //     toast.warning(
      //       response.data.msg === "User Already Exists"
      //         ? "User Already Exist"
      //         : null
      //     );
      //   })
      //   .catch((error) => {
      //     toast.error(error.message);
      //     setLoading(false);
      //   });
      setEmail(values.email);
      setFirstName(values.first_name);
      setLastName(values.last_name);
      setPassword(values.password);
      // action.resetForm();
      setStatus("2");
    },
  });
  return (
    <>
      <form
        onSubmit={formik.handleSubmit}
        className="flex flex-col mx-auto gap-2"
      >
        <p className="text-2xl underline font-bold text-center my-5">
          User Create
        </p>
        <div className="grid grid-cols-2 gap-x-10 gap-y-3">
          <CustomInput
            type="text"
            name="first_name"
            id="first_name"
            value={formik.values["first_name"]}
            onChange={formik.handleChange}
            placeholder="Enter First Name"
            label="First Name"
          />
          <CustomInput
            type="text"
            name="last_name"
            id="last_name"
            value={formik.values["last_name"]}
            onChange={formik.handleChange}
            placeholder="Enter Last Name"
            label="Last Name"
          />
          <CustomInput
            type="email"
            name="email"
            id="email"
            value={formik.values["email"]}
            onChange={formik.handleChange}
            placeholder="Enter Email"
            label="Email"
          />
          <CustomInput
            type="password"
            name="password"
            id="password"
            value={formik.values["password"]}
            onChange={formik.handleChange}
            placeholder="*********"
            label="Password"
          />
        </div>
        <LoadingButton
          loading={loading}
          variant="contained"
          className="!mx-auto !px-5 !my-7 !p-2"
          type="submit"
        >
          Select Plan Type
        </LoadingButton>
      </form>
    </>
  );
};

export default StoreUserCreate;
