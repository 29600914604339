import React, { useEffect, useState } from "react";
import axios from "axios";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import Select from "react-select";
import { CustomButton } from "../../../shared/CustomButton";
import CustomInput from "../../../shared/CustomInput";
import { resellerStateFn } from "../../../Services/StateList";
import { resellerCityFn } from "../../../Services/CityList";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { baseURL } from "../../../config/axios";

const AddStore = ({ setOpen, CostemerId }) => {
  const [cuntryListData, setCunteryListData] = useState([]);
  const [cuntery, setCuntery] = useState()
  const [stateListData, setStateListData] = useState([]);
  const [state, setState] = useState();
  const [city, setCity] = useState();
  const navigate =useNavigate()

  const [cityListData, setCityListData] = useState();
  const [category, setCategory] = useState();
  const [subCategory, setSubCategory] = useState();
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState([]);

  // const client = useQueryClient();

  // const { mutate: mutateState } = useMutation(resellerStateFn, {
  //   onSuccess: (response) => {
  //     setStateListData(response.data.country_data_list);
  //   },
  // });
 


  // const { mutate: mutateCity } = useMutation(resellerCityFn, {
  //   onSuccess: (response) => {
  //     setCityListData(response.data.city_data_list);
  //   },
  // });

  // useEffect(
  //   () => {
  //     state ? mutateCity({ state_id: state }) : mutateState({ country_id: 10 });
  //   },
  //   // eslint-disable-next-line
  //   [state, city]
  // );

  const CategoryList = () => {
    axios
      .post(
        `${baseURL}reseller_app/business-category-list-data/`,
        {},
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        setCategory(response.data.business_category_list_data);
        // toast.success(response.data.message);
      })
      .catch((error) => {
        console.log("", error.response);
        console.log(error);
        // alert(error.message);
      });
  };
  const SubCategoryList = () => {
    axios
      .post(
        `${baseURL}reseller_app/business-sub-cat-data-list/`,
        { business_category_ids: selectedCategory },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        setSubCategory(response.data.business_category_list_data);
        // toast.success(response.data.message);
      })
      .catch((error) => {
        console.log("", error.response);
        console.log(error);
        // alert(error.message);
      });
  };

  const ConteryList = () => {
    axios
      .get(
        `https://b1.bhaaraterp.com/reseller_app/country-state-city-for-reseller-list-api-of-bhaaraterp/`,

        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        setCunteryListData(response.data.data);
        // toast.success(response.data.message);
      })
      .catch((error) => {
        console.log("", error.response);
        console.log(error);
        // alert(error.message);
      });
  };
  useEffect(()=>{
    ConteryList()
  },[])

  const StateList = () => {
    axios
      .get(
        `https://b1.bhaaraterp.com/reseller_app/country-state-city-for-reseller-list-api-of-bhaaraterp/?country_id=${cuntery}`,

        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        setStateListData(response.data.data);
        // toast.success(response.data.message);
      })
      .catch((error) => {
        console.log("", error.response);
        console.log(error);
        // alert(error.message);
      });
  };
  useEffect(()=>{
    cuntery && StateList()
  },[cuntery])

  const CityList = () => {
    axios
      .get(
        `https://b1.bhaaraterp.com/reseller_app/country-state-city-for-reseller-list-api-of-bhaaraterp/?country_id=${cuntery}&state_id=${state}`,

        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        setCityListData(response.data.data);
        // toast.success(response.data.message);
      })
      .catch((error) => {
        console.log("", error.response);
        console.log(error);
        // alert(error.message);
      });
  };
  useEffect(()=>{
    state && CityList()
  },[state])

  const contryList =
  cuntryListData &&
  cuntryListData.map((cuntryListData) => {
    return { value: cuntryListData.id, label: cuntryListData.name };
  });
  const stateList =
    stateListData &&
    stateListData.map((stateListData) => {
      return { value: stateListData.id, label: stateListData.state };
    });
  const cityList =
    cityListData &&
    cityListData.map((cityListData) => {
      return { value: cityListData.id, label: cityListData.city };
    });

  const categories =
    category &&
    category.map((category) => {
      return { value: category.id, label: category.name };
    });
  const subCategories =
    subCategory &&
    subCategory.map((subCategory) => {
      return { value: subCategory.id, label: subCategory.name };
    });

  useEffect(
    () => {
      CategoryList();
      SubCategoryList();
    },
    // eslint-disable-next-line
    [selectedCategory]
  );
  // const CloseModal = () => {
  //   client.refetchQueries(["resellerStoreList"]);
  //   setOpen(false);
  // };

  const initialValues = {
    business_store_name: "",
    business_store_email: "",
    business_store_mobile_number: "",
    pincode: "",
    IFSC: "",
    bank_name: "",
    account_no: "",
    gst_number: "",
    address: "",

    swift_code: "",
  };

  const formik = useFormik({
    initialValues: initialValues,

    onSubmit: (values) => {
      const fd = new FormData();
      fd.append("reseller_customer_id", CostemerId);
      fd.append("store_name", values.business_store_name);
      // fd.append("store_email", values.business_store_email);
      fd.append("store_mobile_no", values.business_store_mobile_number);

      fd.append("pincode", values.pincode);
      fd.append("ifsc", values.IFSC);
      fd.append("bank_name", values.bank_name);
      fd.append("account_no", values.account_no);
      fd.append("gst_number", values.gst_number);
      fd.append("website", values.website);
      fd.append("swift_code", values.swift_code);
      fd.append("address", values.address);
      fd.append("business_type_id", selectedSubCategory);
      fd.append("city_id", city);
      fd.append("state_id", state);
      fd.append("country_id", cuntery);
      // fd.append("time_period", storePlan);

      axios
        .post(
          `https://b1.bhaaraterp.com/reseller_app/reseller-add-store-to-reseller-customer-api-of-bhaaraterp/`,
          fd,
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        )
        // .post(`${baseURL}reseller_app/reseller-add-store-data/`, fd, {
        //   headers: {
        //     Authorization: localStorage.getItem("token"),
        //   },
        // })
        .then((response) => {
          toast.success(response.data.mesage);
          toast.success(response.data.message);
          response.data.response_code === 200 && navigate('/resellers/store-Create');
        })
        .catch((error) => {
          console.log(error);
          toast.error(error.message);
        });
    },
  });
  console.log("stateList", stateListData);

  return (
    <>
      <form
        onSubmit={formik.handleSubmit}
        className="flex flex-col  overflow-y-auto mx-auto gap-2"
      >
        <p className="text-2xl underline font-bold text-center my-5">
          Add New Business
        </p>
        <div className="grid grid-cols-2 gap-x-10 gap-y-3 ">
          <div>
            <p className="m-1">Busniness Category</p>
            <Select
              isMulti
              placeholder="Select"
              onChange={(event) =>
                setSelectedCategory(event.map((select) => select.value))
              }
              options={categories}
              className="!rounded-md"
            />
          </div>

          <div>
            <p className="m-1">Busniness Sub Category</p>
            <Select
              isMulti
              placeholder="Select"
              onChange={(event) =>
                setSelectedSubCategory(event.map((select) => select.value))
              }
              options={subCategories}
              className="!rounded-md"
            />
          </div>

          <CustomInput
            type="text"
            name="business_store_name"
            id="business_store_name"
            value={formik.values["business_store_name"]}
            onChange={formik.handleChange}
            placeholder="Aara Technologies Pvt. Ltd."
            label="Business Name"
          />
          <CustomInput
            type="text"
            name="business_store_email"
            id="business_store_email"
            value={formik.values["business_store_email"]}
            onChange={formik.handleChange}
            placeholder="info@aartechnlogies.in"
            label="Business Email"
          />
          <CustomInput
            type="text"
            name="business_store_mobile_number"
            id="business_store_mobile_number"
            value={formik.values["business_store_mobile_number"]}
            onChange={formik.handleChange}
            placeholder="Ex: 9876543210"
            label="Business Contact Number"
          />
          <CustomInput
            type="text"
            name="address"
            id="address"
            value={formik.values["address"]}
            onChange={formik.handleChange}
            placeholder="Ex: C-379, Indira Nagar, Lucknow"
            label="Address"
          />

          <div>
            <p className="m-1">Country</p>
            <Select
              placeholder="Select"
              onChange={(e) => setCuntery(e.value)}
              options={contryList}
              className="!rounded-md"
            />
          </div>
          <div>
            <p className="m-1">State</p>
            <Select
              placeholder="Select"
              onChange={(e) => setState(e.value)}
              options={stateList}
              className="!rounded-md"
            />
          </div>
          <div>
            <p className="m-1">City</p>
            <Select
              placeholder="Select"
              onChange={(e) => setCity(e.value)}
              options={cityList}
              className="!rounded-md"
            />
          </div>

          <CustomInput
            type="text"
            name="pincode"
            id="pincode"
            value={formik.values["pincode"]}
            onChange={formik.handleChange}
            placeholder="Ex: 226016"
            label="Pincode"
          />
          <CustomInput
            type="text"
            name="gst_number"
            id="gst_number"
            value={formik.values["gst_number"]}
            onChange={formik.handleChange}
            placeholder="Ex: 226016"
            label="GSTIN"
          />
          <CustomInput
            type="text"
            name="account_no"
            id="account_no"
            value={formik.values["account_no"]}
            onChange={formik.handleChange}
            placeholder="Ex: 96859758965478"
            label="Account Number"
          />
          <CustomInput
            type="text"
            name="bank_name"
            id="bank_name"
            value={formik.values["bank_name"]}
            onChange={formik.handleChange}
            placeholder="Ex: STATE BANK OF INDIA"
            label="Bank Name"
          />
          <CustomInput
            type="text"
            name="IFSC"
            id="IFSC"
            value={formik.values["IFSC"]}
            onChange={formik.handleChange}
            placeholder="Ex: SBIN00002598"
            label="IFSC Code"
          />
          <CustomInput
            type="text"
            name="swift_code"
            id="swift_code"
            value={formik.values["swift_code"]}
            onChange={formik.handleChange}
            placeholder="Ex: SBIN00002598"
            label="Swift Code"
          />
          <CustomInput
            type="text"
            name="website"
            id="website"
            value={formik.values["website"]}
            onChange={formik.handleChange}
            placeholder="Aaratechnologies.in"
            label="Website"
          />
          {/* <CustomInput
            type="password"
            name="store_password"
            id="store_password"
            value={formik.values["store_password"]}
            onChange={formik.handleChange}
            label="Store Password"
          /> */}
        </div>
        <CustomButton className="!mx-auto !px-5 !my-7 !p-2" type="submit">
          Add Business
        </CustomButton>
      </form>
    </>
  );
};

export default AddStore;
