export const API_URLS = {
  resellerProfile: "reseller_app/reseller-profile-details/",
  resellerState: "state-list-data/",
  resellerCity: "city-list-data/",
  countryList: "country-state-city-list-api-of-bhaaraterp/",
  resellerStoreList: "reseller_app/reseller-customer-list-api-of-bhaaraterp/",
  dashboardGraphData: "reseller_app/reseller-ammount-graph/",
  dashboardGraphData1: "reseller_app/reseller-sales-graph/",
  dashboardGraphData2: "reseller_app/reseller-commission-graph/",
  planList: "reseller_app/reseller-plan-list-api-of-bhaaraterp/",
  // latestOrders: "reseller_app/reseller-plan-list-api-of-bhaaraterp/",
  // planList: "reseller_app/plan_list/",
  latestOrders: "reseller_app/latest-reseller-order-data/",
  resetPasswordFn: "reseller_app/reset-reseller-password-api/",
};