import { Box, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { CustomButton } from "../../../shared/CustomButton";

import ModuleSelect from "../ModuleSelect";
import AddStore from "../AddStore";
import { toast } from "react-toastify";
import StoreUserCreate from "../UserCreate";
import SeletPlan from "../SelectPlan";

const StoreCreation = () => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("3");
  const [status, setStatus] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const[planid,setPlanId]=useState("")
  const [storePlan, setStorePlan] = useState("");
  const [planType, setPlanType] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClose = () => setOpen(false);
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    status === 200
      ? setValue("2")
      : status === 201
      ? setValue("3")
      : status === 202
      ? toast.warning("Payment Processing...")
      : setValue("1");
  }, [status]);

  return (
    <>
      <CustomButton
        className="!px-5 whitespace-nowrap !p-0"
        onClick={handleOpen}
      >
        + Add Business
      </CustomButton>
      <Modal open={open} onClose={handleClose}>
        <Box
          className="absolute top-1/2 left-1/2 bg-white text-black outline-none -translate-x-1/2 -translate-y-1/2 w-11/12 lg:w-1/2 h-1/2 min-h-[93%]
         shadow-md p-6 rounded-lg overflow-y-auto"
        >
          <TabContext value={value}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <TabList onChange={handleChange} className="sticky !capitalize">
                <Tab label="User Create" value="1" />
                <Tab label="Plan Type" value="2" />
                <Tab label="Plan" value="3" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <StoreUserCreate
                setFirstName={setFirstName}
                setLastName={setLastName}
                setStatus={setValue}
                setEmail={setEmail}
                setPassword={setPassword}
              />
            </TabPanel>
            <TabPanel value="3" className="!p-2">
              <ModuleSelect
                setStatus={setStatus}
                setOpen={setOpen}
                email={email}
                password={password}
                firstName={firstName}
                lastName={lastName}
                status={status}
                planId={planid}
                setStorePlan={setStorePlan}
                setPlanType={setPlanType}
              />
            </TabPanel>
            <TabPanel value="2" className="!p-2">
              <SeletPlan
                setStatus={setValue}
                setOpen={setOpen}
                setPlanId={setPlanId}
              />
            </TabPanel>
            {/* <TabPanel value="3">
              <AddStore
                password={password}
                setOpen={setOpen}
                email={email}
                storePlan={storePlan}
                planType={planType}
              />
            </TabPanel> */}
          </TabContext>
        </Box>
      </Modal>
    </>
  );
};

export default StoreCreation;
