import { API_URLS } from "../../config/apiUrls";
import axiosInstance from "../../config/axios";

export const resetPasswordFn = (redBody) => {
  try {
    const response = axiosInstance.post(API_URLS.resetPasswordFn, redBody, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
    return response;
  } catch ({ error }) {
    throw new Error(error.message);
  }
};
