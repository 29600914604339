import React from "react";
import Glassmorphism from "../shared/Glassmorphism";

const Testing = () => {
  return (
    <Glassmorphism>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt eius
      fugit commodi ipsa veniam? Quo, consequatur esse. Voluptates perspiciatis
      magnam reprehenderit, numquam dolorum provident, officiis est possimus,
      quasi corrupti aut!
    </Glassmorphism>
  );
};

export default Testing;
