import { BrowserRouter, Routes, Route } from "react-router-dom";
import SignIn from "./components/SignIn";
import ResetPassword from "./components/SignIn/ResetPassword";
import Layout from "./Layout";
import { resellersRoutes } from "./routes";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/reset-paasword" element={<ResetPassword />} />
          {resellersRoutes.map((route) => {
            return (
              <Route
                key={route.id}
                path={route.path}
                element={<Layout component={route.component} />}
              />
            );
          })}
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
