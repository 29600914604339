import { useFormik } from "formik";
import { useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import CustomInput from "../../shared/CustomInput";
import { CustomButton } from "../../shared/CustomButton";
import CustomModal from "../../shared/CustomModal";
import { Button } from "@mui/material";
import { resetPasswordFn } from "../../Services/ResetPassword";

const ResetPassword = ({ reseller_id }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    formik.resetForm();
  };

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  const { mutate: resetPassword, isLoading } = useMutation(resetPasswordFn, {
    onSuccess: (response) => {
      if (response?.data?.response_code === 200) {
        toast.success(response.data.message);
        handleClose();
        formik.resetForm();
      } else {
        toast.error(response.data.message);
      }
    },
  });

  const initialValues = { new_password: "", confirm_password: "" };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      const reqBody = {
        // reseller_id: reseller_id,
        new_password: values.new_password,
        confirm_password: values.confirm_password,
      };
      resetPassword(reqBody);
    },
  });

  return (
    <>
      <Button
        disableElevation
        variant="contained"
        className="!capitalize  !text-lg !text-black !bg-slate-100 !text-black "
        onClick={handleOpen}
      >
        Change Password
      </Button>
      {/* <CustomButton
        className="bg-primary text-white"
        size="small"
        onClick={handleOpen}
      >
        Reset
      </CustomButton> */}

      <CustomModal
        open={open}
        // onClose={handleClose}
        setOpen={setOpen}
        className="!w-[530px]"
        title="Reset Password"
      >
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-2">
          <CustomInput
            name="new_password"
            onChange={formik.handleChange}
            type="password"
            isRequired
            label="New Password"
            placeholder="••••••••••••"
          />
          <CustomInput
            onChange={formik.handleChange}
            name="confirm_password"
            type="password"
            isRequired
            label="Confirm Password"
            placeholder="••••••••••••"
          />
          <CustomButton
            isLoading={isLoading}
            type="submit"
            variant="contained"
            className="!mt-5 !w-full !py-2 !text-base  !text-white !flex !justify-center !mx-auto"
          >
            Reset
          </CustomButton>
        </form>
      </CustomModal>
    </>
  );
};

export default ResetPassword;
