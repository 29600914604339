import {
  Checkbox,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

const PlanDetails = ({ choosePlans }) => {
  return (
    <div className="flex flex-col h-full w-full my-auto pr-3">
      <Divider className="text-2xl">{choosePlans}</Divider>

      <div>
        <TableContainer
          component="div"
          className="!flex !justify-center !items-center"
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="!border-none !text-lg">
                  Basic Features
                </TableCell>
                <TableCell className="!border-none !text-lg">
                  <Checkbox  checked />
                </TableCell>
                <TableCell className="!border-none !text-lg">
                  <Checkbox  />
                </TableCell>
                <TableCell className="!border-none !text-lg">
                  <Checkbox  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className="!border-none !text-lg">Users</TableCell>
                <TableCell className="!border-none !text-lg">
                  <Checkbox  checked/>
                </TableCell>
                <TableCell className="!border-none !text-lg">
                  <Checkbox  checked/>
                </TableCell>
                <TableCell className="!border-none !text-lg">
                  <Checkbox  checked/>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="!border-none !text-lg">
                  Individual Data
                </TableCell>
                <TableCell className="!border-none !text-lg">
                  <Checkbox  checked/>
                </TableCell>
                <TableCell className="!border-none !text-lg">
                  <Checkbox  checked/>
                </TableCell>
                <TableCell className="!border-none !text-lg">
                  <Checkbox  checked/>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="!border-none !text-lg">Dessert</TableCell>
                <TableCell className="!border-none !text-lg">
                  <Checkbox  checked/>
                </TableCell>
                <TableCell className="!border-none !text-lg">
                  <Checkbox  checked/>
                </TableCell>
                <TableCell className="!border-none !text-lg">
                  <Checkbox  checked/>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="!border-none !text-lg">Dessert</TableCell>
                <TableCell className="!border-none !text-lg">
                  <Checkbox  checked/>
                </TableCell>
                <TableCell className="!border-none !text-lg">
                  <Checkbox  checked/>
                </TableCell>
                <TableCell className="!border-none !text-lg">
                  <Checkbox  checked/>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="!border-none !text-lg">Flexible Work Environment</TableCell>
                <TableCell className="!border-none !text-lg">
                  <Checkbox  checked/>
                </TableCell>
                <TableCell className="!border-none !text-lg">
                  <Checkbox  checked/>
                </TableCell>
                <TableCell className="!border-none !text-lg">
                  <Checkbox  checked/>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default PlanDetails;
