import {DomainAdd} from "@mui/icons-material";
import {
  Button,
  Checkbox,
  CircularProgress,
  List,
  ListItem,
} from "@mui/material";
import axios from "axios";
import classNames from "classnames";
import React, {useState} from "react";
import {useEffect} from "react";
import { useMutation, useQueries, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { planListFn } from "../../../Services/PlanList";
import { Navigate, useNavigate } from "react-router-dom";
import { BallTriangle } from "react-loader-spinner";
// import { planListFn } from "../../../Services/PlanList";
import { baseURL } from "../../../config/axios";

const ModuleSelect = ({
  setStatus,
  email,
  password,
  lastName,
  firstName,
  status,
  setPlanType,
  setStorePlan,
  setOpen,
  planId,
}) => {
  const [progress, setProgress] = useState(false);
  const [loading, setLoading] = useState(false);
  const [plan, setPlan] = useState("Monthly");
  const [planList, setPlanList] = useState([]);
  const [choosePlans, setChoosePlans] = useState("Advance");

  const navigate = useNavigate();

  const client = useQueryClient();
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  // const {mutate: planListData, isLoading} = useMutation(planListFn, {
  //   onSuccess: (response) => {
  //     setPlanList(response.data.data);
  //     setLoading(true);
  //   },
  // });
  const { data: planListData, isLoading } = useQuery(
    ["planList", planId, plan],
    () => planListFn({ plan_type_id: planId, time_period: plan }),
    {
      // onSuccess: (response) => {
      //   setPlanList(response.data.data);
      //   setLoading(true);
      // },
    }
  );
  useEffect(
    () => {
      // planListData({ plan_type_id: planId, time_period: plan });
      setPlanList(planListData?.data?.data);
      setLoading(true);
      console.log("aaaaaa", planId, plan, planListData?.data?.data);
    },
    // eslint-disable-next-line
    [planListData]
  );
  const CloseModal = () => {
    client.refetchQueries(["resellerStoreList"]);
    setOpen(false);
  };
  const HandleClickYearly = () => {
    setPlan("Yearly");
  };
  const HandleClickMonthly = () => {
    setPlan("Monthly");
  };
  const handleAdvance = () => {
    setChoosePlans("Advance");
  };
  const handleBasic = () => {
    setChoosePlans("Basic");
  };
  const handleEnterprise = () => {
    setChoosePlans("Enterprise");
  };

  const Basic = planList && planList[0];
  const Advance = planList && planList[1];
  const Enterprise = planList && planList[2];

  useEffect(() => {
    setStorePlan(plan);
  }, [plan]);

  const SendPaymentLink = () => {
    const reqbodyBasic = {
      last_name: lastName,
      first_name: firstName,
      password: password,
      email: email,
      plan_period: plan,
      plan_id: Basic?.id,
    };
    const reqbodyAdvance = {
      last_name: lastName,
      first_name: firstName,
      password: password,
      email: email,
      plan_period: plan,
      plan_id: Advance?.id,
    };
    const reqbodyEnterprise = {
      last_name: lastName,
      first_name: firstName,
      password: password,
      email: email,
      plan_period: plan,
      plan_id: Enterprise?.id,
    };
    // https://b1.bhaaraterp.com/reseller_app/reseller-buy-license/
    axios
      .post(
        `${baseURL}reseller_app/register-reseller-customer-api-of-bhaaraterp/`,
        // `${baseURL}reseller_app/reseller-buy-license/`,
        choosePlans === "Advance"
          ? reqbodyAdvance
          : choosePlans === "Basic"
          ? reqbodyBasic
          : choosePlans === "Enterprise"
          ? reqbodyEnterprise
          : null,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        toast.success(response.data.message);
        setStatus(response.data.response_code);
        response.data.response_code === 200 ? CloseModal() : setOpen(true);
      })
      .catch((error) => {});
    choosePlans === "Advance"
      ? setPlanType(Advance?.id)
      : choosePlans === "Basic"
      ? setPlanType(Basic?.id)
      : choosePlans === "Enterprise"
      ? setPlanType(Enterprise?.id)
      : setPlanType(null);
  };

  const PaymentStatus = () => {
    axios
      .post(
        `${baseURL}reseller_app/check-payment-status/`,
        { email: email },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        setStatus(response.data.response_code);
        setLoading(Math.random());
      })
      .catch((error) => {});
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 120 ? 0 : prevProgress + 1
      );
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  // useEffect(
  //   () => {
  //     console.log("jkjkj", status);
  //     status === 200 ? PaymentStatus() : setLoading(false);
  //   },
  // eslint-disable-next-line
  //   [loading]
  // );
  return (
    <>
      {isLoading ? (
        <div className="flex justify-center items-center pt-32">
          <BallTriangle
            className="items-center"
            height={200}
            width={200}
            radius={5}
            color="#4fa94d"
            ariaLabel="ball-triangle-loading"
            wrapperClass={{}}
            wrapperStyle=""
            visible={true}
          />
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center w-full">
          <div className="flex gap-4 justify-center items-center">
            <div
              onClick={HandleClickMonthly}
              className={classNames(
                "flex p-2 items-center rounded justify-center cursor-pointer hover:bg-blue-600 hover:!text-white",
                plan === "Monthly" ? "bg-blue-600 !text-white" : ""
              )}
            >
              Monthly
            </div>
            <div
              onClick={HandleClickYearly}
              className={classNames(
                "flex p-2 items-center rounded justify-center cursor-pointer hover:bg-blue-600 hover:!text-white",
                plan === "Yearly" ? "bg-blue-600 !text-white" : ""
              )}
            >
              Yearly
            </div>
          </div>

          <div className="lg:flex justify-between gap-6 my-5">
            {planList && planList[0]?.plan_name === "Inventory" && (
              <div
                onClick={handleBasic}
                className={classNames(
                  "flex flex-col !w-[33%] bg-slate-200 p-5 hover:border-blue-600 cursor-pointer border-2 border-transparent hover:border-2 rounded lg:my-0 my-5",
                  choosePlans === "Basic"
                    ? "border-blue-600 border-2 transition-transform duration-100 scale-105"
                    : ""
                )}
              >
                <span className="flex gap-2">
                  <DomainAdd className="!text-2xl font-semibold" />
                  <p className="text-2xl font-semibold">{Basic?.plan_name}</p>
                </span>

                <List>
                  {Basic?.modules?.map((module) => {
                    return (
                      <ListItem key={module} className="!p-0">
                        {module.status ? (
                          <Checkbox
                            {...label}
                            defaultChecked
                            color="secondary"
                          />
                        ) : (
                          <Checkbox {...label} color="success" />
                        )}
                        <p className="font-bold">{module.value}</p> &nbsp;
                        <p>{module?.name}</p>
                      </ListItem>
                    );
                  })}
                </List>
                {plan === "Monthly" && (
                  <p className="text-3xl font-bold text-end mx-5">
                    ₹{Basic?.monthly_price}
                    <span className="!text-base capitalize">/{plan}</span>
                  </p>
                )}
                {plan === "Yearly" && (
                  <p className="text-3xl font-bold text-end mx-5">
                    ₹{Basic?.yearly_price}
                    <span className="!text-base capitalize">/{plan}</span>
                  </p>
                )}
                {/* <Button variant="contained" className="!capitalize">
                See Details
              </Button> */}
              </div>
            )}
            {planList?.[1]?.plan_name === "Trial" && (
              <div
                onClick={handleAdvance}
                className={classNames(
                  "flex flex-col !w-[33%] bg-slate-200 p-5 hover:border-blue-600 cursor-pointer border-2 border-transparent hover:border-2 rounded lg:my-0 my-5",
                  choosePlans === "Advance"
                    ? "border-blue-600 border-2 transition-transform duration-100 scale-105"
                    : ""
                )}
              >
                <span className="flex gap-2">
                  <DomainAdd className="!text-2xl font-semibold" />
                  <p className="text-2xl font-semibold">{Advance?.plan_name}</p>
                </span>
                <List>
                  {Advance?.modules?.map((module) => {
                    return (
                      <ListItem key={module} className="!p-0">
                        <Checkbox checked />
                        <p>{module?.name}</p>
                      </ListItem>
                    );
                  })}
                </List>
                {plan === "Monthly" && (
                  <p className="text-3xl font-bold text-end mx-5">
                    ₹{Advance?.monthly_price}
                    <span className="!text-base capitalize">/{plan}</span>
                  </p>
                )}
                {plan === "Yearly" && (
                  <p className="text-3xl font-bold text-end mx-5">
                    ₹{Advance?.yearly_price}
                    <span className="!text-base capitalize">/{plan}</span>
                  </p>
                )}
                {/* <Button variant="contained" className="!capitalize">
                See Details
              </Button> */}
              </div>
            )}
            {planList?.[2]?.plan_name ===
              "ERP B2B + B2C (Inventory) Full plan" && (
              <div
                onClick={handleEnterprise}
                className={classNames(
                  "flex flex-col !w-[34%] bg-slate-200 p-5 hover:border-blue-600 cursor-pointer border-2 border-transparent hover:border-2 rounded",
                  choosePlans === "Enterprise"
                    ? "border-blue-600 border-2 transition-transform duration-100 scale-105"
                    : ""
                )}
              >
                <span className="flex gap-2">
                  <DomainAdd className="!text-2xl font-semibold" />
                  <p className="text-2xl font-semibold">
                    {Enterprise?.plan_name}
                  </p>
                </span>
                <List>
                  {Enterprise?.modules?.map((module) => {
                    return (
                      <ListItem key={module} className="!p-0">
                        <Checkbox checked />
                        <p>{module?.name}</p>
                      </ListItem>
                    );
                  })}
                </List>
                {plan === "Monthly" && (
                  <p className="text-3xl font-bold text-end mx-5">
                    ₹{Enterprise?.monthly_price}
                    <span className="!text-base capitalize">/{plan}</span>
                  </p>
                )}
                {plan === "Yearly" && (
                  <p className="text-3xl font-bold text-end mx-5">
                    ₹{Enterprise?.yearly_price}
                    <span className="!text-base capitalize">/{plan}</span>
                  </p>
                )}

                {/* <Button variant="contained" className="!capitalize">
                See Details
              </Button> */}
              </div>
            )}
          </div>
          {/* <PlanDetails choosePlans={choosePlans} /> */}
          <Button
            onClick={SendPaymentLink}
            disableElevation
            // size="small"
            variant="contained"
            className="!bg-blue-600 !mt-7  !w-full !text-lg !font-semibold !capitalize"
          >
            Save
          </Button>
        </div>
      )}
      {/* <form
        onSubmit={formik.handleSubmit}
        className="flex flex-col mx-auto gap-2"
      >
        <p className="text-2xl underline font-bold text-black text-center my-7">
          Select Module
        </p>
        <div className="grid grid-cols-2 gap-x-20 gap-y-10">
          <div className="flex justify-between">
            <p className="text-left text-xl">ERP</p>
            <input
              type="checkbox"
              id="erp_val"
              name="erp_val"
              checked={formik.values["erp_val"]}
              onChange={formik.handleChange}
              className="checkbox checkbox-info"
            />
          </div>
          <div className="flex justify-between">
            <p className="text-left text-xl">HRM</p>
            <input
              type="checkbox"
              id="hrm_val"
              name="hrm_val"
              checked={formik.values["hrm_val"]}
              onChange={formik.handleChange}
              className="checkbox checkbox-info"
            />
          </div>
          <div className="flex justify-between">
            <p className="text-left text-xl">API</p>
            <input
              type="checkbox"
              id="api_val"
              name="api_val"
              checked={formik.values["api_val"]}
              onChange={formik.handleChange}
              className="checkbox checkbox-info"
            />
          </div>

          <div className="flex justify-between">
            <p className="text-left text-xl">Purchase</p>
            <input
              type="checkbox"
              id="purchase_val"
              name="purchase_val"
              checked={formik.values["purchase_val"]}
              onChange={formik.handleChange}
              className="checkbox checkbox-info"
            />
          </div>
          <div className="flex justify-between">
            <p className="text-left text-xl">Sales</p>
            <input
              type="checkbox"
              id="sales_val"
              name="sales_val"
              checked={formik.values["sales_val"]}
              onChange={formik.handleChange}
              className="checkbox checkbox-info"
            />
          </div>
          <div className="flex justify-between">
            <p className="text-left text-xl">Ledger</p>
            <input
              type="checkbox"
              id="ledger_val"
              name="ledger_val"
              checked={formik.values["ledger_val"]}
              onChange={formik.handleChange}
              className="checkbox checkbox-info"
            />
          </div>
          <div className="flex justify-between">
            <p className="text-left text-xl">Leads</p>
            <input
              type="checkbox"
              id="leads_val"
              name="leads_val"
              checked={formik.values["leads_val"]}
              onChange={formik.handleChange}
              className="checkbox checkbox-info"
            />
          </div>
          <div className="flex justify-between">
            <p className="text-left text-xl">Report</p>
            <input
              type="checkbox"
              id="report_val"
              name="report_val"
              checked={formik.values["report_val"]}
              onChange={formik.handleChange}
              className="checkbox checkbox-info"
            />
          </div>
        </div>
        <input
          type="number"
          name="emp_val"
          id="emp_val"
          value={formik.values["emp_val"]}
          onChange={formik.handleChange}
          placeholder="Enter Numbers Of Employees"
          label="Employees"
          className="rounded p-1 mt-5 outline-none bg-white w-full text-black border-opacity-30 border border-zinc-600"
        />
        <LoadingButton
          loading={loading === true ? loading : false}
          variant="contained"
          className="!mx-auto !px-5 !my-7 !p-2"
          type="submit"
        >
          {loading === true ? "Payment Pending" : "Send Payment Link"}
        </LoadingButton>
      </form> */}
    </>
  );
};

export default ModuleSelect;
