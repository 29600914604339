import MonetizationOnTwoToneIcon from '@mui/icons-material/MonetizationOnTwoTone';
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useMutation, useQuery } from "react-query";
import { dashboardGraphDataFn } from "../../../Services/DashboardGraph";
import { CircularProgress } from "@mui/material";
import { dashboardGraphData1Fn } from "../../../Services/DashboardGraph1";
import { dashboardGraphData2Fn } from "../../../Services/DashboardGraph2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const ResellersGraph = ({ filter }) => {
  const [chartData, setChartData] = useState([]);
  const [chartData1, setChartData1] = useState([]);
  const [chartData2, setChartData2] = useState([]);

  const { data: firstLine, isLoading } = useQuery(
    ["TotalSales", filter],
    () => dashboardGraphDataFn({ time_period: filter })
    // {
    //   onSuccess: (response) => {},
    // }
  );

  const { data: secondLine } = useQuery(["TotalAmount", filter], () =>
    dashboardGraphData1Fn({ time_period: filter })
  );
  const { data: thirdLine } = useQuery(["TotalCommission", filter], () =>
    dashboardGraphData2Fn({
      time_period: filter,
    })
  );
  useEffect(
    () => {
      setChartData1(secondLine?.data?.data);
      setChartData(firstLine?.data?.data);
      setChartData2(thirdLine?.data?.data);
    },
    // eslint-disable-next-line
    [filter]
  );
  console.log("aaaa", chartData, chartData1, chartData2);
  const graphdata = {
    labels: chartData && chartData[1]?.date,
    datasets: [
      {
        label: "Total Revenue",
        data: chartData && chartData[0]?.reseller_sales_amount_list,
        borderColor: "#FF9933",
        borderWidth: 6,
      },

      {
        label: "Total Commissions",
        data:
          chartData2 && chartData2[0]?.reseller_commission_sales_list !== null
            ? chartData2 && chartData2[0]?.reseller_commission_sales_list
            : [0],
        borderColor: "#FFFFFF",
        borderWidth: 6,
      },
      {
        label: "Number Of Sales",
        data: chartData1 && chartData1[0]?.reseller_sales_count_list,
        borderColor: "#138808",
        borderWidth: 6,
      },
    ],
  };

  return (
    <div className="flex flex-col pr-2">
      <div className="grid lg:grid-cols-3 grid-cols-1 gap-5">
        <div className="bg-white bg-opacity-10 h-40 backdrop-blur-[5px] border-2 border-white border-opacity-30 shadow-card p-5 rounded-md cursor-pointer">
          <div className="flex gap-4 items-center">
            <MonetizationOnTwoToneIcon color="success" fontSize="large" />
            <p className="">Number Of Sale</p>
          </div>
          {isLoading ? (
            <div className="flex justify-center items-center">
              <CircularProgress size={70} />
            </div>
          ) : (
            <p className="text-7xl font-semibold text-center">
              {chartData1 && chartData1[0]?.reseller_total_sales_count
                ? chartData1 && chartData1[0]?.reseller_total_sales_count
                : 0}
            </p>
          )}
        </div>
        <div className="bg-white bg-opacity-10 h-40 backdrop-blur-[5px] border-2 border-white border-opacity-30 shadow-card p-5 rounded-md cursor-pointer">
          <div className="flex gap-4 items-center">
            <MonetizationOnTwoToneIcon color="success" fontSize="large" />
            <p className="">Total Amount</p>
          </div>
          {isLoading ? (
            <>
              <div className="flex justify-center items-center">
                <CircularProgress size={70} />
              </div>
            </>
          ) : (
            <div className="flex justify-center items-center">
              <p className="text-7xl font-semibold">
                ₹
                {chartData && chartData[0]?.reseller_total_sales_amount
                  ? chartData && chartData[0]?.reseller_total_sales_amount
                  : 0}
              </p>
            </div>
          )}
        </div>
        <div className="bg-white bg-opacity-10 h-40 backdrop-blur-[5px] border-2 border-white border-opacity-30 shadow-card p-5 rounded-md cursor-pointer">
          <div className="flex gap-4 items-center">
            <MonetizationOnTwoToneIcon color="success" fontSize="large" />
            <p className="">Total Commission</p>
          </div>
          {isLoading ? (
            <>
              <div className="flex justify-center items-center">
                <CircularProgress size={70} />
              </div>
            </>
          ) : (
            <div className="flex justify-center items-center">
              <p className="text-7xl font-semibold">
                ₹
                {chartData2 && chartData2[0]?.reseller_commission_sales
                  ? chartData2 && chartData2[0]?.reseller_commission_sales
                  : 0}
              </p>
            </div>
          )}
        </div>

        <Line
          data={graphdata}
          options={{
            legend: {
              labels: {
                boxWidth: 10,
                fontSize: 30,
              },
            },
            responsive: true,
          }}
          height={100}
          className="p-3 shadow-card border-white border-opacity-30 bg-white bg-opacity-20 backdrop-blur-[5px] !rounded-md !text-black"
        />
      </div>
    </div>
  );
};

export default ResellersGraph;
