import { Divider } from "@mui/material";
import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import ResellerUpdateProfile from "../ResellerUpdateProfile";
import { baseURL } from "../../config/axios";

const ResellerProfile = () => {
  const [data, setData] = useState([]);
  const email = localStorage.getItem("email");
  // const { mutate } = useMutation(resellerProfileFn, {
  //   onSuccess: (response) => {
  //     setData(response.data?.data[0]);
  //   },
  // });
  const ProfileDetails = () => {
    axios
      .post(
        `${baseURL}reseller_app/reseller-profile-details/`,
        { email: email },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        setData(response.data?.data[0]);
      })
      .catch((error) => {
        console.log("", error);
      });
  };
  useEffect(
    () => {
      ProfileDetails();
      // mutate({ email: email });
    },
    // eslint-disable-next-line
    [email]
  );

  return (
    <div>
      <div className="flex justify-between pb-2 items-center">
        <p className="">Reseller Details</p>
        <ResellerUpdateProfile data={data} />
      </div>
      <div className="flex gap-5">
        <div className="flex flex-col gap-3 w-1/4">
          <div className="bg-white bg-opacity-25 backdrop-blur-[5px] border-2 border-white border-opacity-40 rounded-md py-5 flex flex-col justify-center items-center shadow-card">
            <img
              src={
                data?.profile_img
                  ? data?.profile_img
                  : "https://cdn.pixabay.com/photo/2020/07/01/12/58/icon-5359553_960_720.png"
              }
              alt=""
              className="rounded-full h-52"
            />
          </div>
          <div className="bg-white bg-opacity-25 backdrop-blur-[5px] border-2 border-white border-opacity-40 rounded-md py-2 flex flex-col justify-center items-center shadow-card">
            <p>Adhar Card</p>
            <img src={data?.adhar_card} alt="" className="h-32" />
          </div>
          <div className="bg-white bg-opacity-25 backdrop-blur-[5px] border-2 border-white border-opacity-40 rounded-md py-2 flex flex-col justify-center items-center shadow-card">
            <p>Pan Card</p>
            <img src={data?.pan_card} alt="" className="h-32" />
          </div>
        </div>
        <div className="w-3/4 bg-white bg-opacity-25 backdrop-blur-[5px] border-2 border-white border-opacity-40 rounded-md p-3 shadow-card">
          <p className="mb-2 mx-2 font-semibold">Basic Details</p>
          <Divider />
          <div className="grid grid-cols-2 p-2 my-2">
            <div className="flex gap-5">
              <p>First Name : </p> <p>{data?.first_name}</p>
            </div>
            <div className="flex gap-5">
              <p>Last Name : </p> <p>{data?.last_name}</p>
            </div>
          </div>
          <div className="grid grid-cols-2 p-2">
            <div className="flex gap-5">
              <p>Email : </p> <p>{data?.email}</p>
            </div>
            <div className="flex gap-5">
              <p>Phone : </p> <p>{data?.mobile_number}</p>
            </div>
          </div>
          <div className="grid grid-cols-2 p-2">
            <div className="flex gap-5">
              <p>Country : </p> <p>{data?.country}</p>
            </div>
            <div className="flex gap-5">
              <p>State : </p> <p>{data?.state}</p>
            </div>
          </div>
          <div className="grid grid-cols-2 p-2">
            <div className="flex gap-5">
              <p>City : </p> <p>{data?.city}</p>
            </div>
            <div className="flex gap-5">
              <p>Zip Code : </p> <p>{data?.zip_code}</p>
            </div>
          </div>
          <div className="grid grid-cols-2 p-2">
            <div className="flex gap-5">
              <p>Permanent Address : </p> <p>{data?.permanent_address}</p>
            </div>
            <div className="flex gap-5">
              <p>Correspondence Address : </p>{" "}
              <p>{data?.correspondence_address}</p>
            </div>
          </div>
          <div className="grid grid-cols-2 p-2">
            <div className="flex gap-5">
              <p>Adhar Number : </p> <p>{data?.adhar_card_number}</p>
            </div>
            <div className="flex gap-5">
              <p>Pan Number: </p> <p>{data?.pan_card_number}</p>
            </div>
          </div>
          <Divider />
          <p className="m-2 font-semibold">Bank Details</p>
          <Divider />
          <div className="grid grid-cols-2 p-2">
            <div className="flex gap-5">
              <p>Bank Name : </p> <p>{data?.bank_name}</p>
            </div>
            <div className="flex gap-5">
              <p>Account Type : </p> <p>{data?.account_type}</p>
            </div>
          </div>
          <div className="grid grid-cols-2 p-2">
            <div className="flex gap-5">
              <p>Account No. : </p> <p>{data?.account_number}</p>
            </div>
            <div className="flex gap-5">
              <p>IFSC No. : </p> <p>{data?.ifsc_code}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResellerProfile;
