import React from "react";
import classNames from "classnames";
import { TextField } from "@mui/material";

const Search = ({ className }) => {
  return (
    <TextField
      size="small"
      placeholder="Search..."
      className={classNames(
        "!z-[9999] !bg-white !bg-opacity-20 !border-opacity-30 !border !border-white ",
        className
      )}
    />
  );
};

export default Search;

