import * as Yup from "yup";

export const logInSchema = Yup.object({
  email: Yup.string()
    .email("Enter Valid Email.")
    .required("Email is required."),
  password: Yup.string()
    .min(5, "Password Must Be At Least 5 Characters.")
    .required("Password is required."),
});
