import { API_URLS } from "../../config/apiUrls";
import axiosInstance from "../../config/axios";

export const dashboardGraphDataFn = (redBody) => {
  try {
    const response = axiosInstance.get(API_URLS.dashboardGraphData, {
      params: redBody,
    });
    return response;
  } catch ({ error }) {
    throw new Error(error.message);
  }
};
