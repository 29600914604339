import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { Collapse, Fade, List } from "@mui/material";
const Sidebar = () => {
  const [checked, setChecked] = React.useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };
  return (
    <>
      <div className="flex flex-col h-full bg-white bg-opacity-30 backdrop-blur-xl shadow-card border-r-2 border-white border-opacity-20 justify-between">
        <div className="flex flex-col lg:my-0 my-5">
          <List className="lg:flex lg:flex-col flex-row ">
            <Link
              to="/resellers/dashboard"
              className={classNames(
                "p-2 lg:px-3 px-1 m-2 w-52",
                window.location.pathname === "/resellers/dashboard"
                  ? "w-full bg-blue-600 text-white rounded"
                  : ""
              )}
            >
              Dashboard
            </Link>
            <p
              to="#"
              className={classNames(
                "p-2 lg:px-3 px-1 m-2 w-52",
                window.location.pathname === "#"
                  ? "w-full bg-blue-600 text-white rounded"
                  : ""
              )}
              onClick={handleChange}
            >
           Management
            </p>
            <Collapse in={checked} >
              <div>
              <Link
                to="/resellers/store-management"
                className={classNames(
                  "p-2 lg:px-3 px-1 m-2 w-52",
                  window.location.pathname === "/resellers/store-management"
                    ? "w-full bg-blue-600 text-white rounded"
                    : ""
                )}
              >
                Reseller Customer 
              </Link>
              </div>

              <div className="my-5">
              <Link
                to="/resellers/store-Create"
                className={classNames(
                  "p-2 lg:px-3 px-1 m-2 w-52",
                  window.location.pathname === "/resellers/store-Create"
                    ? "w-full bg-blue-600 text-white rounded"
                    : ""
                )}
              >
               Reseller Store 
              </Link>
              </div>
            </Collapse>

            <Link
              to="/resellers/orders-history"
              className={classNames(
                "p-2 lg:px-3 px-1 m-2 w-52",
                window.location.pathname === "/resellers/orders-history"
                  ? "w-full bg-blue-600 text-white rounded"
                  : ""
              )}
            >
              Orders History
            </Link>
          </List>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
