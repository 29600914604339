import React from "react";
import Header from "../shared/Header";
import Sidebar from "../shared/Sidebar";

const Layout = ({ component }) => {
  return (
    <>
      <div
        className="sticky top-0 w-full transition-all duration-500"
        style={{
          backgroundImage: `url("https://images.unsplash.com/photo-1632691013308-d1b25d3ff20f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80")`,
        }}
      >
        <Header />
        <div className="lg:flex lg:h-[89.6vh]">
          <Sidebar />
          <div className="w-full px-4 pt-2 h-[89.6vh] overflow-y-auto bg-white bg-opacity-30 backdrop-blur-xl">
            {component}
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
