import axios from "axios";

// export const baseURL = "http://192.168.1.188:9094/";
export const baseURL = "https://b1.bhaaraterp.com/"

const axiosInstance = axios.create({
  baseURL: baseURL,
  // baseURL: "https://b1.bhaaraterp.com/",
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    config.headers = {
      Authorization: token,
      ...config.headers,
    };
    return config;
  },
  (err) => Promise.reject(err)
);

export default axiosInstance;
