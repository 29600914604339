import { Circle } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab, TableCell, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { latestOrdersFn } from "../../Services/LatestOrders";
import ResellersGraph from "./ResellersGraph";

const ResellersDashboard = () => {
  const [value, setValue] = useState("1");
  const [latestOrdersData, setLatestOrdersData] = useState([]);
  const navigate = useNavigate();

  const email = localStorage.getItem("email");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { mutate: latestOrders, isLoading } = useMutation(latestOrdersFn, {
    onSuccess: (response) => {
      setLatestOrdersData(response.data?.Data);
    },
  });
  useEffect(
    () => {
      latestOrders({ email: email });
    },
    // eslint-disable-next-line
    []
  );
  useEffect(
    () => {
      localStorage.getItem("role") === "reseller"
        ? navigate("#")
        : navigate("/");
    },
    // eslint-disable-next-line
    []
  );
  return (
    <>
      <Box className="">
        <TabContext value={value}>
          <Box className="bg-white bg-opacity-30 rounded-md">
            <TabList onChange={handleChange} variant="scrollable">
              <Tab label="Today" value="1" />
              <Tab label="Yesterday" value="2" />
              <Tab label="Last 7 Day" value="3" />
              <Tab label="Last 15 Day" value="4" />
              <Tab label="Last 30 Day" value="5" />
              <Tab label="Last 90 Day" value="6" />
            </TabList>
          </Box>
          <TabPanel className="!p-2 !py-3" value="1">
            <ResellersGraph filter={"today"} />
          </TabPanel>
          <TabPanel className="!p-2 !py-3" value="2">
            <ResellersGraph filter={"yesterday"} />
          </TabPanel>
          <TabPanel className="!p-2 !py-3" value="3">
            <ResellersGraph filter={"last_7_days"} />
          </TabPanel>
          <TabPanel className="!p-2 !py-3" value="4">
            <ResellersGraph filter={"last_15_days"} />
          </TabPanel>
          <TabPanel className="!p-2 !py-3" value="5">
            <ResellersGraph filter={"last_30_days"} />
          </TabPanel>
          <TabPanel className="!p-2 !py-3" value="6">
            <ResellersGraph filter={"last_90_days"} />
          </TabPanel>
        </TabContext>
      </Box>
      <div className="mx-2 !mr-4">
        <div className="flex justify-between">
          <p className="text-xl font-bold">Latest Orders</p>
          <Link to="/resellers/orders-history">View All</Link>
        </div>
        <div className="flex flex-col gap-3 my-2">
          {latestOrdersData?.map((orders, index) => {
            return (
              <TableRow
                key={index + 1}
                component="div"
                className="bg-white bg-opacity-10 backdrop-blur-[5px] w-full rounded-md border-white border-opacity-30 border-b-none"
              >
                <TableCell
                  component="span"
                  className="min-w-[275px] !p-10 border-b !border-dark"
                >
                  {orders.store_name}
                </TableCell>
                <TableCell
                  component="span"
                  className="min-w-[275px] !p-10 border-b !border-dark"
                >
                  {orders.reseller_data.payment_id}
                </TableCell>
                <TableCell
                  component="span"
                  className="min-w-[275px] !p-10 border-b !border-dark"
                >
                  {orders.reseller_data.updated_date.slice(0, 10)}
                </TableCell>
                <TableCell
                  component="span"
                  className="min-w-[275px] !p-10 border-b !border-dark"
                >
                  {orders.reseller_data.reference_id}
                </TableCell>
                <TableCell
                  component="span"
                  className="min-w-[275px] !p-10 border-b !border-dark"
                >
                  {orders.reseller_data.is_paid === true ? (
                    <>
                      <Circle color="success" className="!mx-2" />
                      Paid
                    </>
                  ) : (
                    <>
                      <Circle color="error" className="!mx-2" />
                      Unpaid
                    </>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </div>
      </div>
      {/* <div className="mx-2 !mr-4">
        <div className="flex justify-between">
          <p className="text-xl font-bold">Latest Request</p>
          <Link to="#">View All</Link>
        </div>
        <div className="flex flex-col gap-3 my-2">
          {[1, 2, 3].map((index) => {
            return (
              <TableRow
                key={index}
                component="div"
                className="bg-white bg-opacity-10 backdrop-blur-[5px] w-full rounded-md border-white border-opacity-30 "
              >
                <TableCell
                  component="span"
                  className="min-w-[275px] !p-10 border-b !border-dark"
                >
                  <span className="flex items-center gap-3">
                    <Avatar
                      src="https://staticimg.titan.co.in/Tanishq/Catalog/511240FAGAA00_1.jpg?impolicy=pqmed&imwidth=640"
                      variant="rounded"
                    />
                    Yukta Hearts Gold Ring
                  </span>
                </TableCell>
                <TableCell
                  component="span"
                  className="min-w-[180px] !border-dark !p-10"
                >
                  ABC-175815
                </TableCell>
                <TableCell
                  component="span"
                  className="min-w-[180px] !border-dark !p-10"
                >
                  Stark
                </TableCell>
                <TableCell
                  component="span"
                  className="min-w-[180px] !border-dark !p-10"
                >
                  Ring
                </TableCell>
                <TableCell
                  component="span"
                  className="min-w-[180px] !border-dark !p-10"
                >
                  7515
                </TableCell>
                <TableCell
                  component="span"
                  className="min-w-[180px] !border-dark !p-10"
                >
                  02/02/2023
                </TableCell>
                <TableCell
                  component="span"
                  className="min-w-[180px] !border-dark !p-10"
                >
                  <Circle color="success" /> Pending
                </TableCell>
              </TableRow>
            );
          })}
        </div>
      </div> */}
    </>
  );
};

export default ResellersDashboard;
