import { Box, Modal } from "@mui/material";
import React, { useState } from "react";
import axios from "axios";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { CustomButton } from "../../shared/CustomButton";
import { Edit } from "@mui/icons-material";
import { baseURL } from "../../config/axios";

const ResellerUpdateProfile = ({ data }) => {
  const [open, setOpen] = useState(false);
  const [adharCard, setAdharCard] = useState(null);
  const [panCard, setPanCard] = useState(null);
  const [profileImage, setProfileImage] = useState(null);

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const initialValues = {
    country: data?.country,
    state: data?.state,
    city: data?.city,
    ifsc_code: data?.ifsc_code,
    bank_name: data?.bank_name,
    account_number: data?.account_number,
    adhar_card_number: data?.adhar_card_number,
    permanent_address: data?.permanent_address,
    pan_card_number: data?.pan_card_number,
    correspondance_address: data?.correspondance_address,
    zip_code: data?.zip_code,
  };
  const email = localStorage.getItem("email");
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,

    onSubmit: (values, action) => {
      const reqBody = new FormData();
      reqBody.append("email", email);
      reqBody.append("country", values.country);
      reqBody.append("state", values.state);
      reqBody.append("city", values.city);
      reqBody.append("ifsc_code", values.ifsc_code);
      reqBody.append("bank_name", values.bank_name);
      reqBody.append("account_number", values.account_number);
      reqBody.append("adhar_card_number", values.adhar_card_number);
      reqBody.append("permanent_address", values.permanent_address);
      reqBody.append("pan_card_number", values.pan_card_number);
      reqBody.append("correspondance_address", values.correspondance_address);
      reqBody.append("zip_code", values.zip_code);
      reqBody.append("adhar_card_image", adharCard);
      reqBody.append("pan_card_image", panCard);
      reqBody.append("profile_image", profileImage);

      axios
        .post(`${baseURL}reseller_app/update-reseller-profile/`, reqBody, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log(response.data);
          toast.success(response.data.message);
          handleClose();
        })
        .catch((error) => {
          console.log("", error.response);
          console.log(error);
          // alert(error.message);
        });
      //   action.resetForm();
    },
  });
  return (
    <>
      <CustomButton onClick={handleOpen}>
        <Edit />
        Update
      </CustomButton>
      <Modal open={open} onClose={handleClose}>
        <Box className="absolute top-1/2 left-1/2 bg-white outline-none -translate-x-1/2 -translate-y-1/2 w-2/5 h-4/5 overflow-y-auto shadow-md p-6 rounded">
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-col text-black mx-auto gap-1"
          >
            <p className="text-2xl underline font-semibold text-center my-5">
              Update Profile
            </p>
            <div className="grid grid-cols-2 gap-4">
              <div className="flex flex-col flex-wrap">
                <p className="text-left">Profile Image</p>
                <input
                  type="file"
                  className="rounded-md p-1 focus:outline-none border-2 bg-white border-black"
                  onChange={(event) => setProfileImage(event.target.files[0])}
                />
              </div>
              <div className="flex flex-col">
                <p className="text-left">Adhar Card</p>
                <input
                  type="file"
                  className="rounded-md p-1 focus:outline-none border-2 bg-white border-black"
                  onChange={(event) => setAdharCard(event.target.files[0])}
                />
              </div>
              <div className="flex flex-col">
                <p className="text-left">Pan Card</p>
                <input
                  type="file"
                  className="rounded-md p-1 focus:outline-none border-2 bg-white border-black"
                  onChange={(event) => setPanCard(event.target.files[0])}
                />
              </div>
              <div className="flex flex-col">
                <p className="text-left">Country</p>
                <input
                  autoComplete="off"
                  className="rounded-md p-2 focus:outline-none border-2 bg-white border-black"
                  name="country"
                  id="country"
                  value={formik.values["country"]}
                  onChange={formik.handleChange}
                  placeholder="Enter Country"
                />
              </div>
              <div className="flex flex-col">
                <p className="text-left">State</p>
                <input
                  autoComplete="off"
                  className="rounded-md p-2 focus:outline-none border-2 bg-white border-black"
                  name="state"
                  id="state"
                  value={formik.values["state"]}
                  onChange={formik.handleChange}
                  placeholder="Enter State"
                />
              </div>
              <div className="flex flex-col">
                <p className="text-left">City</p>
                <input
                  autoComplete="off"
                  className="rounded-md p-2 focus:outline-none border-2 bg-white border-black"
                  name="city"
                  id="city"
                  value={formik.values["city"]}
                  onChange={formik.handleChange}
                  placeholder="Enter City"
                />
              </div>
              <div className="flex flex-col">
                <p className="text-left">Zip Code</p>
                <input
                  autoComplete="off"
                  className="rounded-md p-2 focus:outline-none border-2 bg-white border-black"
                  name="zip_code"
                  id="zip_code"
                  value={formik.values["zip_code"]}
                  onChange={formik.handleChange}
                  placeholder="Enter Zip Code"
                />
              </div>
              <div className="flex flex-col">
                <p className="text-left">Permanent Address</p>
                <input
                  autoComplete="off"
                  className="rounded-md p-2 focus:outline-none border-2 bg-white border-black"
                  name="permanent_address"
                  id="permanent_address"
                  value={formik.values["permanent_address"]}
                  onChange={formik.handleChange}
                  placeholder="Enter Permanent Address"
                />
              </div>
              <div className="flex flex-col">
                <p className="text-left">Correspondance Address</p>
                <input
                  autoComplete="off"
                  className="rounded-md p-2 focus:outline-none border-2 bg-white border-black"
                  name="correspondance_address"
                  id="correspondance_address"
                  value={formik.values["correspondance_address"]}
                  onChange={formik.handleChange}
                  placeholder="Enter Correspondance Address"
                />
              </div>
              <div className="flex flex-col">
                <p className="text-left">Bank Name</p>
                <input
                  autoComplete="off"
                  className="rounded-md p-2 focus:outline-none border-2 bg-white border-black"
                  name="bank_name"
                  id="bank_name"
                  value={formik.values["bank_name"]}
                  onChange={formik.handleChange}
                  placeholder="Enter Bank Name"
                />
              </div>
              <div className="flex flex-col">
                <p className="text-left">Account Number</p>
                <input
                  autoComplete="off"
                  className="rounded-md p-2 focus:outline-none border-2 bg-white border-black"
                  name="account_number"
                  id="account_number"
                  value={formik.values["account_number"]}
                  onChange={formik.handleChange}
                  placeholder="Enter Account Number"
                />
              </div>
              <div className="flex flex-col">
                <p className="text-left">IFSC Code</p>
                <input
                  autoComplete="off"
                  className="rounded-md p-2 focus:outline-none border-2 bg-white border-black"
                  name="ifsc_code"
                  id="ifsc_code"
                  value={formik.values["ifsc_code"]}
                  onChange={formik.handleChange}
                  placeholder="Enter IFSC Code"
                />
              </div>
              <div className="flex flex-col">
                <p className="text-left">Adhar Card Number</p>
                <input
                  autoComplete="off"
                  className="rounded-md p-2 focus:outline-none border-2 bg-white border-black"
                  name="adhar_card_number"
                  id="adhar_card_number"
                  value={formik.values["adhar_card_number"]}
                  onChange={formik.handleChange}
                  placeholder="Enter Adhar Card Number"
                />
              </div>
              <div className="flex flex-col">
                <p className="text-left">Pan Card Number</p>
                <input
                  autoComplete="off"
                  className="rounded-md p-2 focus:outline-none border-2 bg-white border-black"
                  name="pan_card_number"
                  id="pan_card_number"
                  value={formik.values["pan_card_number"]}
                  onChange={formik.handleChange}
                  placeholder="Enter Pan Card Number"
                />
              </div>
            </div>

            <CustomButton className="!mx-auto px-4 !my-7 !p-2" type="submit">
              Update Profile
            </CustomButton>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default ResellerUpdateProfile;
