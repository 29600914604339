import { Button } from "@mui/material";
import classNames from "classnames";
import React from "react";

export const CustomButton = ({ onClick, type, className, children }) => {
  return (
    <>
      <Button
        onClick={onClick}
        disableElevation
        type={type}
        size="small"
        variant="contained"
        className={classNames(
          "my-auto p-1 mx-2 !capitalize !bg-blue-600 !rounded-md px-2",
          className
        )}
      >
        {children}
      </Button>
    </>
  );
};
