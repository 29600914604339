import { DownloadTwoTone, FilterAlt } from "@mui/icons-material";
import {
  Checkbox,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import axios from "axios";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "../../shared/CustomButton";
import { CustomIconButton } from "../../shared/CustomIconButton";
import Loader from "../../shared/Loader";
import Search from "../../shared/Search";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { baseURL } from "../../config/axios";

const OrdersHistory = () => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const tableRef = useRef(null);

  const handleChangePage = (event, newPage) => {
    setPage(+event.target.value);
    setPage(newPage);
  };
  function increment() {
    setPage(page + 1)
  }

  function decrement() {
    setPage(page - 1)
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const OrdersHistoryList = () => {
    axios
      .get(
        `${baseURL}reseller_app/reseller-order-histroy-api-of-bhaaraterp/?page=${page}`,

        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(
    () => {
      OrdersHistoryList();
    },
    // eslint-disable-next-line
    [page]
  );
  const navigate = useNavigate();
  useEffect(
    () => {
      localStorage.getItem("role") === "reseller"
        ? navigate("#")
        : navigate("/");
    },
    // eslint-disable-next-line
    []
  );

  return (
    <>
      <div className="">
        <div className="flex justify-between">
          <div className="p-2">
            <p className="text-xl font font-bold">Orders History List</p>
          </div>
        </div>
        <div className="flex flex-col !rounded border-2 border-white border-opacity-40 backdrop-blur-xl m-2">
          <div className="flex items-center justify-between my-2">
            <div className="flex  gap-3">
              <CustomIconButton>
                <FilterAlt />
              </CustomIconButton>
              <Search />
            </div>
            <DownloadTableExcel
              filename="BhaaratERP"
              sheet="users"
              currentTableRef={tableRef.current}
            >
              <CustomButton className="!mx-3 !font-semibold">
                <DownloadTwoTone className="mr-1" /> Export
              </CustomButton>
            </DownloadTableExcel>
          </div>
          <TableContainer
            component="div"
            className="bg-white bg-opacity-30 backdrop-blur-xl"
          >
            <Table ref={tableRef}>
              <TableHead>
                <TableRow className="!border-b-2 border-black">
                  <TableCell className="!text-left !p-2">
                    <Checkbox className="!m-0" />
                  </TableCell>
                  <TableCell className="!text-center !font-bold">
                    Store Name
                  </TableCell>
                  <TableCell className="!text-center !font-bold">
                    Payment ID
                  </TableCell>
                  <TableCell className="!text-center !font-bold">
                    Payment Date
                  </TableCell>
                  <TableCell className="!text-center !font-bold">
                    Reference ID
                  </TableCell>
                  <TableCell className="!text-right !font-bold">
                    Amount
                  </TableCell>
                  <TableCell className="!text-right !font-bold">
                    Status
                  </TableCell>
                </TableRow>
                {/* {data.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={6} className="!p-0 !m-0">
                      <Loader />
                    </TableCell>
                  </TableRow>
                ) : null} */}
              </TableHead>

              <TableBody>
                {data.map((row, index) => (
                    <TableRow
                      key={row?.id}
                      className="!border-b-2 border-black"
                    >
                      <TableCell
                        className="!text-left !p-2"
                        component="th"
                        scope="row"
                      >
                        <Checkbox className="!m-0" />
                      </TableCell>
                      <TableCell className="!text-center">
                        {row?.reseller_customer?.first_name}
                      </TableCell>
                      <TableCell className="!text-center">
                        {row?.payment_id}
                      </TableCell>
                      <TableCell className="!text-center">
                        {row?.date.slice(0, 10)}
                      </TableCell>
                      <TableCell className="!text-center">
                        {row?.reference_id}
                      </TableCell>
                      <TableCell className="!text-center">
                        {row?.amount}
                      </TableCell>
                      <TableCell className="!text-right">
                        {row?.is_paid === true ? (
                          <Chip
                            label="Paid"
                            color="success"
                            className="!px-3"
                          />
                        ) : (
                          <Chip
                            label="Unpaid"
                            color="error"
                            className="!px-3"
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <div className="flex my-5 px-5 gap-5 justify-end items-end">
              <ChevronLeftIcon className="!text-3xl" onClick={decrement} />
              <ChevronRightIcon className="!text-3xl" onClick={increment} />
            </div>
            {/* {data?.length !== 0 ? (
              <TablePagination
                rowsPerPageOptions={[10, 20, 30, 40]}
                count={data?.length}
                component="div"
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              /> */}
            {/* ) : null} */}
          </TableContainer>
        </div>
      </div>
    </>
  );
};

export default OrdersHistory;
