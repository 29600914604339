import { DomainAdd } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  CircularProgress,
  List,
  ListItem,
} from "@mui/material";
import axios from "axios";
import classNames from "classnames";
import React, { useState } from "react";
import { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";
import { BallTriangle, InfinitySpin } from "react-loader-spinner";
import { baseURL } from "../../../config/axios";

const SeletPlan = ({ setStatus, setPlanId, setOpen }) => {
  const [data, setData] = useState([]);
  const [loding, setloding] = useState(true);

  const navigate = useNavigate();

  const client = useQueryClient();

  // console.log("selectPlan",firstName)
  // console.log("selectPlan",password,)
  // console.log("selectPlan", email)
  // console.log("selectPlan",lastName)
  const PaymentStatus = () => {
    axios
      .get(
        `${baseURL}reseller_app/reseller-plan-type-list-api-of-bhaaraterp/`,

        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        setData(response.data.data);
        setloding(false);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    PaymentStatus();
  }, []);

  console.log("data Plane list", data);

  return (
    <>
      {loding ? (
        <div className="flex justify-center items-center pt-32">
          <BallTriangle
            className="items-center"
            height={200}
            width={200}
            radius={5}
            color="#4fa94d"
            ariaLabel="ball-triangle-loading"
            wrapperClass={{}}
            wrapperStyle=""
            visible={true}
          />
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center w-full">
          <div className="justify-center grid lg:grid-cols-2 grid-cols-1 gap-9 my-5">
            {data?.map((item) => {
              return (
                <div onClick={() => setPlanId(item?.id)} className="card my-5">
                  <img src={item.image} alt="" />
                  <div className="card__content">
                    <p className="card__title border-b-2 ">{item.title}</p>
                    <p className="card__description">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco.
                    </p>
                  </div>
                </div>
              );
            })}
          </div>

          <Button
            onClick={() => setStatus("3")}
            disableElevation
            size="small"
            variant="contained"
            className="!bg-blue-600 !capitalize !py-2"
          >
            Select Plan
          </Button>
        </div>
      )}

      {/* <form
        onSubmit={formik.handleSubmit}
        className="flex flex-col mx-auto gap-2"
      >
        <p className="text-2xl underline font-bold text-black text-center my-7">
          Select Module
        </p>
        <div className="grid grid-cols-2 gap-x-20 gap-y-10">
          <div className="flex justify-between">
            <p className="text-left text-xl">ERP</p>
            <input
              type="checkbox"
              id="erp_val"
              name="erp_val"
              checked={formik.values["erp_val"]}
              onChange={formik.handleChange}
              className="checkbox checkbox-info"
            />
          </div>
          <div className="flex justify-between">
            <p className="text-left text-xl">HRM</p>
            <input
              type="checkbox"
              id="hrm_val"
              name="hrm_val"
              checked={formik.values["hrm_val"]}
              onChange={formik.handleChange}
              className="checkbox checkbox-info"
            />
          </div>
          <div className="flex justify-between">
            <p className="text-left text-xl">API</p>
            <input
              type="checkbox"
              id="api_val"
              name="api_val"
              checked={formik.values["api_val"]}
              onChange={formik.handleChange}
              className="checkbox checkbox-info"
            />
          </div>

          <div className="flex justify-between">
            <p className="text-left text-xl">Purchase</p>
            <input
              type="checkbox"
              id="purchase_val"
              name="purchase_val"
              checked={formik.values["purchase_val"]}
              onChange={formik.handleChange}
              className="checkbox checkbox-info"
            />
          </div>
          <div className="flex justify-between">
            <p className="text-left text-xl">Sales</p>
            <input
              type="checkbox"
              id="sales_val"
              name="sales_val"
              checked={formik.values["sales_val"]}
              onChange={formik.handleChange}
              className="checkbox checkbox-info"
            />
          </div>
          <div className="flex justify-between">
            <p className="text-left text-xl">Ledger</p>
            <input
              type="checkbox"
              id="ledger_val"
              name="ledger_val"
              checked={formik.values["ledger_val"]}
              onChange={formik.handleChange}
              className="checkbox checkbox-info"
            />
          </div>
          <div className="flex justify-between">
            <p className="text-left text-xl">Leads</p>
            <input
              type="checkbox"
              id="leads_val"
              name="leads_val"
              checked={formik.values["leads_val"]}
              onChange={formik.handleChange}
              className="checkbox checkbox-info"
            />
          </div>
          <div className="flex justify-between">
            <p className="text-left text-xl">Report</p>
            <input
              type="checkbox"
              id="report_val"
              name="report_val"
              checked={formik.values["report_val"]}
              onChange={formik.handleChange}
              className="checkbox checkbox-info"
            />
          </div>
        </div>
        <input
          type="number"
          name="emp_val"
          id="emp_val"
          value={formik.values["emp_val"]}
          onChange={formik.handleChange}
          placeholder="Enter Numbers Of Employees"
          label="Employees"
          className="rounded p-1 mt-5 outline-none bg-white w-full text-black border-opacity-30 border border-zinc-600"
        />
        <LoadingButton
          loading={loading === true ? loading : false}
          variant="contained"
          className="!mx-auto !px-5 !my-7 !p-2"
          type="submit"
        >
          {loading === true ? "Payment Pending" : "Send Payment Link"}
        </LoadingButton>
      </form> */}
    </>
  );
};

export default SeletPlan;
