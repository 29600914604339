import OrdersHistory from "../components/OrdersHistory";
import ResellersDashboard from "../components/ResellersDashboard";
import ResellerProfile from "../components/ResellerProfile";
import ResellerUpdateProfile from "../components/ResellerUpdateProfile";
import StoreList from "../components/StoreManagement/StoreList";
import PlanDetails from "../components/PricingAndPlans/PlanDetails";
import Testing from "../Testing";
import StoreCreate from "../components/StoreManagement/StoreCreate";


export const resellersRoutes = [
  {
    id: 1,
    path: "/resellers/dashboard",
    component: <ResellersDashboard />,
  },
  {
    id: 2,
    path: "/resellers/store-management",
    component: <StoreList />,
  },
  {
    id: 3,
    path: "/resellers/profile",
    component: <ResellerProfile />,
  },
  {
    id: 4,
    path: "/resellers/update-profile",
    component: <ResellerUpdateProfile />,
  },
  {
    id: 6,
    path: "/resellers/orders-history",
    component: <OrdersHistory />,
  },
  {
    id: 7,
    path: "/resellers/pricing-and-plans/details",
    component: <PlanDetails />,
  },
  {
    id: 8,
    path: "/resellers/testing",
    component: <Testing />,
  },
  {
    id: 9,
    path: "/resellers/store-Create",
    component: <StoreCreate />,
  },
];
