import React from "react";

const Glassmorphism = ({ children, onClick }) => {
  return (
    <div onClick={onClick} className="bg-glass">
      {children}
    </div>
  );
};

export default Glassmorphism;
