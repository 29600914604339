import { API_URLS } from "../../../config/apiUrls";
import axiosInstance from "../../../config/axios";


export const resellerStoreListFn = (redBody) => {
  try {
    const response = axiosInstance.get(API_URLS.resellerStoreList, redBody);
    return response;
  } catch ({ error }) {
    throw new Error(error.message);
  }
};