import classNames from "classnames";
import React from "react";

const CustomInput = ({
  type,
  value,
  onChange,
  placeholder,
  label,
  className,
  className2,
  name,
  id,
  disabled,
  hidden,
}) => {
  return (
    <>
      <div className={classNames("w-full", className2)}>
        <p className="m-1">{label}</p>
        <input
          id={id}
          name={name}
          type={type}
          disabled={disabled}
          hidden={hidden}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className={classNames(
            "rounded p-1 outline-none bg-white w-full text-black border-opacity-30 border border-zinc-600",
            className,
            type === "date" ? "uppercase" : ""
          )}
        />
      </div>
    </>
  );
};

export default CustomInput;
