import { API_URLS } from "../../config/apiUrls";
import axiosInstance from "../../config/axios";

export const latestOrdersFn = (redBody) => {
  try {
    const response = axiosInstance.post(API_URLS.latestOrders, redBody);
    return response;
  } catch ({ error }) {
    throw new Error(error.message);
  }
};
