import React, { useState } from "react";
import { Avatar, Button, Menu } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/authentication/logobharaterp.webp";
import Search from "../Search";
import axios from "axios";
import { toast } from "react-toastify";
import { Notifications } from "../Notifications";
import { baseURL } from "../../config/axios";
import ResetPassword from "../../components/ResetPassword";

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  const email = localStorage.getItem("email");
  const user_name = localStorage.getItem("user_name");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutReseller = () => {
    axios
      .post(
        `${baseURL}reseller_app/reseller-log-out/`,
        {},
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        toast.success(response.data.message);
        localStorage.clear();
        navigate("/");
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.data?.message);
      });
  };

  return (
    <>
      <div className="lg:flex bg-white py-1 bg-opacity-30 shadow-card backdrop-blur-xl gap-5 border-white border-b border-opacity-20">
        <div className="flex items-center justify-between ml-3 lg:mx-3">
          <img
            src={logo}
            alt="brandlogo"
            className="cursor-pointer h-16 w-44 mr-2 p-1"
          />
          <h1 className="hidden lg:flex text-3xl cursor-pointer whitespace-nowrap">
            BHARAT-ERP
          </h1>
          <div className="flex lg:hidden items-center justify-center cursor-pointer rounded-md">
            <div className="flex lg:mr-5">
              <Notifications />
              {/* <IconButton className="!bg-white !bg-opacity-30">
                <EmailTwoToneIcon className="!text-3xl text-white text-opacity-50" />
              </IconButton> */}
            </div>

            <div className="flex px-2 bg-transparent lg:bg-white lg:bg-opacity-5 lg:mr-3 hover:lg:!bg-opacity-10 lg:backdrop-blur lg:border lg:border-opacity-10 lg:border-white items-center rounded-md">
              <Avatar
                alt={user_name}
                src="broken.jpg"
                className=" !bg-white !bg-opacity-30 !text-black"
                onClick={handleClick}
              />
              <div
                className="hidden lg:flex lg:flex-col  px-2 py-0.5"
                onClick={handleClick}
              >
                <h1 className="font-bold tracking-wider uppercase">{role}</h1>
                <h1 className="">{user_name}</h1>
              </div>
            </div>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              className="!absolute !right-20 !top-3"
              disableScrollLock
              elevation={10}
            >
              <div className="flex flex-col gap-2 justify-center items-center w-52">
                <Avatar
                  alt={user_name}
                  src="broken.jpg"
                  className="!h-16 !w-16"
                />
                <div className="flex flex-col items-center">
                  <p>{user_name}</p>
                  <p className="text-xs ">{email}</p>
                </div>

                <div className="flex flex-col justify-between w-full gap-1 p-2 border-t-2 ">
                  {/* <Link to="/resellers/profile"> */}
                  <Button
                    disableElevation
                    variant="contained"
                    className="!capitalize  !bg-slate-100 !text-lg !text-black "
                    onClick={() => navigate("/resellers/profile")}
                  >
                    Profile
                  </Button>
                  {/* </Link> */}

                  <ResetPassword />
                  <Button
                    disableElevation
                    variant="contained"
                    className="!capitalize  !bg-slate-100  !text-lg !text-black"
                    onClick={handleLogoutReseller}
                  >
                    Logout
                  </Button>
                </div>
              </div>
            </Menu>

            {/* <KeyboardArrowDownIcon /> */}
          </div>
        </div>

        <div className="flex items-center justify-between w-full">
          <div className="lg:ml-16 ml-3 w-full ">
            <Search className="!border-none w-[96%] lg:w-[25%]" />
          </div>

          <div className="hidden lg:flex items-center justify-center cursor-pointer rounded-md lg:px-3">
            <div className="flex lg:mr-5">
              <Notifications />
              {/* <IconButton className="!bg-white !bg-opacity-30">
                <EmailTwoToneIcon className="!text-3xl text-white text-opacity-50" />
              </IconButton> */}
            </div>

            <div className="flex px-2 bg-transparent lg:bg-white lg:bg-opacity-5 lg:mr-3 hover:lg:!bg-opacity-10 lg:backdrop-blur lg:border lg:border-opacity-10 lg:border-white items-center rounded-md">
              <Avatar
                alt={user_name}
                src="broken.jpg"
                className=" !bg-white !bg-opacity-30 !text-black"
                onClick={handleClick}
              />
              <div
                className="hidden lg:flex lg:flex-col  px-2 py-0.5"
                onClick={handleClick}
              >
                <h1 className="font-bold tracking-wider uppercase">{role}</h1>
                <h1 className="text-sm whitespace-nowrap ">{user_name}</h1>
              </div>
            </div>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              className="!absolute !right-20 !top-3"
              disableScrollLock
              elevation={10}
            >
              <div className="flex flex-col gap-2 justify-center items-center w-52">
                <Avatar
                  alt={user_name}
                  src="broken.jpg"
                  className="!h-16 !w-16"
                />
                <div className="flex flex-col items-center">
                  <p className="whitespace-nowrap">{user_name}</p>
                  <p className="text-xs whitespace-nowrap ">{email}</p>
                </div>

                <div className="flex flex-col justify-between w-full gap-1 p-2 border-t-2 ">
                  {/* <Link to="/resellers/profile"> */}
                  <Button
                    disableElevation
                    variant="contained"
                    className="!capitalize  !bg-slate-100 !text-lg !text-black "
                    onClick={() => navigate("/resellers/profile")}
                  >
                    Profile
                  </Button>
                  {/* </Link> */}

                  <ResetPassword />
                  <Button
                    disableElevation
                    variant="contained"
                    className="!capitalize  !bg-slate-100  !text-lg !text-black"
                    onClick={handleLogoutReseller}
                  >
                    Logout
                  </Button>
                </div>
              </div>
            </Menu>

            {/* <KeyboardArrowDownIcon /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
