import React from "react";
import login from "../../assets/authentication/bg.jpg";
// import logo from "../../assets/authentication/logobharat.png";
import { useFormik } from "formik";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Checkbox, FormHelperText, TextField } from "@mui/material";
import { logInSchema } from "../../schema";
import { useEffect } from "react";
import { baseURL } from "../../config/axios";
import logo from "../../assets/authentication/logobharaterp.webp";

const SignIn = () => {
  const navigate = useNavigate();

  const initialValues = {
    email: "",
    password: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: logInSchema,
    onSubmit: (values, action) => {
      const reqBody = {
        email: values.email,
        password: values.password,
      };

      axios
        .post(`${baseURL}reseller_app/reseller-login/`, {
          ...reqBody,
        })
        .then((response) => {
          console.log("data", response.data);
          localStorage.setItem("token", response?.data.token);
          localStorage.setItem("user_name", response?.data.user_name);
          localStorage.setItem("email", response?.data.email);
          localStorage.setItem("role", "reseller");
          navigate("/resellers/dashboard");
          toast(response.data.message);
        })
        .catch((error) => {
          toast(error?.response?.data.message);
        });
    },
  });

  useEffect(
    () => {
      localStorage.getItem("role") === "reseller"
        ? navigate("/resellers/dashboard")
        : navigate("/");
    },
    // eslint-disable-next-line
    []
  );

  return (
    <>
      <div className="flex">
        <img
          src="https://aaraerp.s3.ap-south-1.amazonaws.com/static/logos/Group-481461.png"
          alt=""
          className="lg:h-[70vh] lg:left-[20vw] lg:top-[15vh] fixed  object-contain w-screen h-screen lg:w-[90vw] "
        />
        <div className="flex flex-col h-screen w-screen items-center lg:p-16 p-8  text-black lg:w-[30%] lg:border-r-2 border-opacity-10 bg-slate-800 bg-opacity-10 lg:backdrop-blur-[6px] backdrop-blur-lg">
          <img src={logo} alt="" className="h-28 mt-8" />
          <p className="text-3xl font-semibold mt-10">Sign In</p>
          <p className="mt-4">Sign in to stay connected.</p>
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-col w-full my-2"
          >
            <label className="py-1 mt-3">Email*</label>
            <TextField
              id="email"
              name="email"
              size="small"
              placeholder="Enter Email"
              value={formik.values["email"]}
              onChange={formik.handleChange}
              onBlur={formik.onBlur}
              type="email"
              className="rounded-md bg-white bg-opacity-20 backdrop-blur-xl"
            />
            <FormHelperText className="!text-red-700 px-1">
              {formik.errors["email"]}
            </FormHelperText>
            <label className="py-1 mt-3">Password*</label>
            <TextField
              id="password"
              name="password"
              size="small"
              placeholder="Enter Password"
              value={formik.values["password"]}
              onChange={formik.handleChange}
              onBlur={formik.onBlur}
              type="password"
              className="rounded-md bg-white bg-opacity-20 backdrop-blur-xl"
            />
            <FormHelperText className="!text-red-700 px-1">
              {formik.errors["password"]}
            </FormHelperText>
            <div className="flex w-full items-center justify-between">
              <div className="flex my-1 items-center">
                <Checkbox className="!p-0 !pr-1" />
                <p className="cursor-pointer">Remember Me</p>
              </div>
              <Link to={"/reset-paasword"}>Forget Password</Link>
            </div>
            <div className="flex justify-center mt-5">
              <Button
                disableElevation
                variant="contained"
                type={"submit"}
                className="!px-10 !bg-blue-600 !py-1.5 !capitalize !rounded-md"
              >
                Sign In
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SignIn;
